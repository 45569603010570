import React, { useState } from 'react';
import axios from 'axios';
import { notification } from 'antd';
import MessageindisnForm from '../Messageindinstatane';
const MessageindForm = () => {
    const [showRepeat, setShowRepeat] = useState(false);
    const toggleRepeat = () => {
        setShowRepeat(!showRepeat);
    };
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dateTime, setDateTime] = useState('');
    // const [sendTime, setSendTime] = useState('');
    const [message, setMessage] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [dateTimeError, setDateTimeError] = useState('');
    const [messageError, setMessageError] = useState('');
    const openNotificationWithIcon = (type, description) => {
        notification[type]({
            message: type === 'success' ? 'Succès' : 'Erreur',
            description,
        });
    };

    

    const envoyerSubmit = async (e) => {
        e.preventDefault();
        const phoneNumberArray = phoneNumber.split(',').map(number => number.trim());

        try {
            const response = await axios.post('http://www.api.messagelinkpro.com/messagelinkpro/message/send/simple', {
                phoneNumber: phoneNumberArray,
                message,
            });
            console.log('Message envoyé :', { phoneNumber, message });
            openNotificationWithIcon('success', 'Le message instantané a été envoyé avec succès.');
        } catch (error) {
            console.error('Erreur lors de l\'envoi du message :', error);
            openNotificationWithIcon('error', 'Une erreur s\'est produite lors de l\'envoi du message instantané.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        let hasError = false;
    
        // Réinitialiser les messages d'erreur
        setPhoneNumberError('');
        setDateTimeError('');
        setMessageError('');
    
        // Validation des champs
        if (!phoneNumber) {
            setPhoneNumberError('Veuillez entrer un ou plusieurs numéros de téléphone.');
            hasError = true;
        }
    
        if (!dateTime) {
            setDateTimeError('Veuillez entrer une date et heure.');
            hasError = true;
        } else {
            const currentTime = new Date();
            const selectedTime = new Date(dateTime);
    
            const minTime = new Date(currentTime.getTime() + 5 * 60000); // 5 minutes à partir de maintenant
            const maxTime = new Date(currentTime.getTime() + 35 * 24 * 60 * 60000); // 35 jours à partir de maintenant
    
            if (selectedTime < minTime) {
                setDateTimeError('L\'heure doit être au moins 5 minutes à partir de maintenant.');
                hasError = true;
            } else if (selectedTime > maxTime) {
                setDateTimeError('La date ne peut pas dépasser 35 jours à partir d\'aujourd\'hui.');
                hasError = true;
            }
        }
    
        if (!message) {
            setMessageError('Veuillez entrer un message.');
            hasError = true;
        }
    
        // Si des erreurs existent, ne pas soumettre le formulaire
        if (hasError) {
            return;
        }
    
        // Envoyer les données après validation
        try {
            const phoneNumberArray = phoneNumber.split(',').map(number => number.trim());
            const response = await axios.post('http://www.api.messagelinkpro.com/messagelinkpro/message/send/programme/uneFois', {
                phoneNumber: phoneNumberArray,
                dateTime,
                message,
            });
    
            console.log('Message envoyé :', { phoneNumber, dateTime, message });
            openNotificationWithIcon('success', 'Le message programmé a été envoyé avec succès.');
        } catch (error) {
            console.error('Erreur lors de l\'envoi du message :', error);
            openNotificationWithIcon('error', 'Une erreur s\'est produite lors de l\'envoi du message programmé.');
        }
    };
    
    return (
        <>
            {/* Page Wrapper */}
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Message programmé</h4>
                                </div>
                                <div className="card-body">
                                   
<form onSubmit={handleSubmit}>
    <div className="row">
        <div className="col-md-6">
            <h4 className="card-title">Message</h4>
            
            <div className="form-group">
                <label>Numéro:</label>
                <input
                    type='text'
                    className={`form-control ${phoneNumberError ? 'is-invalid' : ''}`}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {phoneNumberError && <div className="invalid-feedback">{phoneNumberError}</div>}
            </div>
            
            <div className="form-group">
                <label>Date et Heure:</label>
                <input
                    type="datetime-local"
                    className={`form-control ${dateTimeError ? 'is-invalid' : ''}`}
                    value={dateTime}
                    onChange={(e) => setDateTime(e.target.value)}
                />
                {dateTimeError && <div className="invalid-feedback">{dateTimeError}</div>}
            </div>
    
            <div className="form-group">
                <label>Message:</label>
                <textarea
                    rows="4"
                    className={`form-control h-75 ${messageError ? 'is-invalid' : ''}`}
                    placeholder="Entrer un message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                {messageError && <div className="invalid-feedback">{messageError}</div>}
            </div>
            
        </div>
    </div>
    
    <div className="text-end">
        <button type="submit" className="btn btn-primary">
            Envoyer
        </button>
    </div>
</form>

                                </div>
                            </div>
                        </div>

                        {/* ----------------------------------------- */}

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Message instantané</h4>
                                </div>
                                <div className="card-body">
                                    <MessageindisnForm/>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Form Vertical */}
                </div>
            </div>
            {/* /Page Wrapper */}
        </>
    );
}
export default MessageindForm;
