import React, {useContext, useState, useRef,useEffect } from "react";
import { Table, Modal, Button, Input, notification } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link } from "react-router-dom";
import axios from "axios";
import {AppContext} from "../../services/context";
import { te } from "date-fns/locale";

const Membre = () => {
  const authCtx = useContext(AppContext);
  const {user, onUserChange} = authCtx;
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [cnib, setCnib] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [notifyBg, setNotifyBg] = useState("");
  const [notifyTitle, setNotifyTitle] = useState("");
  const [notifyMessage, setNotifyMessage] = useState("");
  const [formValidate, setFormValidate] = useState("needs-validation");
  const closeRef = useRef();
  const closeEditRef = useRef();
  const notifyRef = useRef();




  const [description, setDescription] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editGroup, setEditGroup] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewGroup, setViewGroup] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [submittedData, setSubmittedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [ nomError,  setNomError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          openNotificationWithIcon("error", "Utilisateur non authentifié. Veuillez vous connecter.");
          return;
        }

       
        const response = await axios.get(
          "http://localhost:8083/member-management/external-api/member/liste",
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setSubmittedData(response.data); 
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchGroups();
  }, []);

  
  const openNotification = (message, description) => {
    notification.success({
      message,
      description,
    });
  };

 

    const handleSubmit = async (e) => {
      e.preventDefault();
      let hasError = false;
  
      // Réinitialiser les messages d'erreur
      setErrorMessage('');
      setNomError('');
      setDescriptionError('');
  
      // Validation des champs
      if (!nom) {
        setNomError('Veuillez entrer un nom du groupe.');
        hasError = true;
      }
      if (!prenom) {
        setDescriptionError('Veuillez entrer une description.');
        hasError = true;
      }
      if (!email) {
        setNomError('Veuillez entrer un nom du groupe.');
        hasError = true;
      }

      if (!telephone) {
        setDescriptionError('Veuillez entrer une description.');
        hasError = true;
      }
      if (!cnib) {
        setNomError('Veuillez entrer un nom du groupe.');
        hasError = true;
      }
     
      // Afficher la notification d'erreur si des champs ne sont pas remplis
      if (hasError) {
        // openNotificationWithIcon('error', "Veuillez remplir tous les champs.");
        return;
      }
    try {
      const response = await axios.post(
        "http://localhost:8083/member-management/external-api/member/create",

        { nom, prenom, email, telephone, cnib, birthdate },
        {
          headers: {
            Authorization: `Bearer ${user.token}`, // Replace with your token
            "Content-Type": "application/json", // Adjust content type if needed
          },
        }
      );
      const newData = { id: response.data.id, nom, prenom, email, telephone, cnib, birthdate,      created: new Date().toISOString() 
      };
      setSubmittedData([newData, ...submittedData]);
      setNom("");
      setDescription("");
      setIsModalVisible(false);
      openNotification("Succès", "Membre ajouté avec succès");
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Erreur lors de l'ajout du membre. Veuillez réessayer.");
    }

    setTimeout(() => { // Fermer le modal après l'ajout réussi 
      if (closeRef.current) { closeRef.current.click(); }
     }, 1000);
    
  };



  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!editGroup || !editGroup.nom || !editGroup.prenom) {
      setErrorMessage("Veuillez remplir tous les champs .");
      return;
    }
    try {
      const response = await axios.put(
        `http://localhost:8083/member-management/external-api/member/modifier/${editGroup.idGroupe}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
        {
          nom: editGroup.nom,
          prenom: editGroup.prenom,
          cnib: editGroup.cnib,
          telephone: editGroup.telephone,
          email: editGroup.email,
          birthdate: editGroup.birthdate,
        }
      );
      const updatedData = submittedData.map((group) =>
        group.idGroupe === editGroup.idGroupe? { ...group, nom: editGroup.nom, prenom: editGroup.prenom, cnib: editGroup.cnib, telephone: editGroup.telephone, email: editGroup.email, birthdate: editGroup.birthdate } : group
      );
      setSubmittedData(updatedData);
      setEditModalVisible(false);
      setEditGroup(null);
      openNotification("Succès", "Membre modifié avec succès");
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Erreur lors de la modification du groupe. Veuillez réessayer.");
    }
  };

  const handleDelete = async (idGroupe) => {
    try {
      await axios.delete(`http://localhost:8083/member-management/external-api/member/delete/${idGroupe}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSubmittedData(submittedData.filter((group) => group.idGroupe !== idGroupe));
      setDeleteModalVisible(false);
      setDeleteGroup(null);
      openNotification("Succès", "Groupe supprimé avec succès");
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Erreur lors de la suppression du groupe. Veuillez réessayer.");
    }
  };

  const configNotify = (bg, title, message) => {
    setNotifyBg(bg);
    setNotifyTitle(title);
    setNotifyMessage(message);
  };

  const handleView = (group) => {
    setViewGroup(group);
    setViewModalVisible(true);
  };

  const handleEdit = (group) => {
    setEditGroup(group);
    setEditModalVisible(true);
  };

  const handleDeleteConfirm = (group) => {
    setDeleteGroup(group);
    setDeleteModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNom("");
    setDescription("");
    setErrorMessage("");
  };

  const filteredData = submittedData.filter(
    (group) =>
      group.nom.toLowerCase().includes(searchText.toLowerCase()) ||
      group.description.toLowerCase().includes(searchText.toLowerCase())
  );
  const sortedFilteredData = [...filteredData].sort((a, b) => new Date(b.created) - new Date(a.created));

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7 col-auto">
                <h3 className="page-title">Membres</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active">Membres</li>
                </ul>
                <div className="top-nav-search">
                    <form>
                    
                        <input
                            className="form-control"
                            placeholder="Rechercher "
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            />
                        <button className="btn" type="submit">
                            <i className="fa fa-search"/>
                        </button>
                    </form>
                </div>
              </div>
              <div className="col-sm-5 col">
                {/* <button
                  className="btn btn-primary float-end mt-2"
                  onClick={() => setIsModalVisible(true)}
                >
                  Ajouter
                </button> */}

                <button
                className="btn btn-primary float-end mt-2"
                type="button"
                // onClick={() => setIsModalVisible(true)}
                data-bs-toggle="modal"
                data-bs-target="#newEmploye"
               
              >
                Ajouter Membre
              </button>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      columns={[
                        {
                          title: "Ordre",
                          render: (text, record, index) => index + 1,
                        },
                        {
                          title: "Nom",
                          dataIndex: "nom",
                         
                        },
                        {
                          title: "Prenom",
                          dataIndex: "prenom",
                         
                        },
                        {
                          title: "Email",
                          dataIndex: "email",
                         
                        },
                        {
                          title: "Telephone",
                          dataIndex: "telephone",
                         
                        },
                        {
                          title: "Cnib",
                          dataIndex: "cnib",
                         
                        },
                        // {
                        //   title: "Description",
                        //   dataIndex: "description",
                        // },
                        {
                          title: "Date d'ajout",
                          dataIndex: "created",
                          key: "created",
                          render: (text) => {
                            if (!text) {
                              return "";
                            }

                            const date = new Date(text);
                            const formattedDate = date.toLocaleDateString("fr-FR");
                            const formattedTime = date.toLocaleTimeString("fr-FR", {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            });

                            return `${formattedDate} ${formattedTime}`;
                          },
                        },
                        {
                          title: "Action",
                          className: "text-center",
                          render: (text, record) => (
                            <div className="text-center">
                              <a
                                href="#"
                                className="me-1 btn btn-sm bg-primary-light"
                                onClick={() => handleView(record)}
                              >
                                <i className="fe fe-eye"></i> Voir
                              </a>
                              <a
                                href="#"
                                className="me-1 btn btn-sm bg-success-light"
                                onClick={() => handleEdit(record)}
                              >
                                <i className="fe fe-pencil"></i> Editer
                              </a>
                              <a
                                href="#"
                                className="me-1 btn btn-sm bg-danger-light"
                                onClick={() => handleDeleteConfirm(record)}
                              >
                                <i className="fe fe-trash"></i> Supprimer
                              </a>
                            </div>
                          ),
                        },
                      ]}

                      dataSource={sortedFilteredData}  
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<Modal
        title="Ajouter un Groupe"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <form onSubmit={handleSubmit}>
          <div className="row form-row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Nom</label>
                <input
                  type="text"
                  className="form-control"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)} 
                />
                 {setNomError && (
                  <small className="text-danger">{nomError}</small>
       )}
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  value={description}   
                  onChange={(e) => setDescription(e.target.value)} 
                ></textarea>
                {descriptionError && (
                  <small className="text-danger">{descriptionError}</small>
       )}
       
  
              </div>
            </div>
          </div>
          {errorMessage && (
            <div className="text-danger mb-3">{errorMessage}</div>
          )}
          <button type="submit" className="btn btn-primary w-100">
            Enregistrer
          </button>
        </form>
      </Modal>



  <div className="modal fade" id="newEmploye">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content">
      <div className="modal-header border-0">
        <h4 className="modal-title text-medium text-bold">
          Ajout D'un membre
        </h4>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
        ></button>
      </div>
      <div className="modal-body">
        {notifyBg !== "" && (
          <FormNotify
            bg={notifyBg}
            title={notifyTitle}
            message={notifyMessage}
          />
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-3 mt-3">
            <label className="form-label">Nom</label>
            <input
              type="text"
              className="form-control"
              placeholder="Entrer le nom de famille de l’employé(e)"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              required
            />
            <div className="invalid-feedback">Veuillez entrer un nom</div>
          </div>
          <div className="mb-3">
            <label htmlFor="fname" className="form-label">Prénom(s)</label>
            <input
              type="text"
              className="form-control"
              id="fname"
              placeholder="Entrer le ou les prénom(s) de l’employé(e)"
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              required
            />
            <div className="invalid-feedback">Veuillez entrer un prénom</div>
          </div>
          <div className="mb-3">
            <label htmlFor="date" className="form-label">Date de naissance</label>
            <input
              type="date"
              className="form-control"
              id="date"
              placeholder="Entrer la date de naissance"
              value={birthdate}
              onChange={(e) => setBirthdate(e.target.value)}
              required
            />
            <div className="invalid-feedback">Veuillez entrer une date de naissance</div>
          </div>
          <div className="mb-3">
            <label htmlFor="cni" className="form-label">Numéro CNI</label>
            <input
              type="text"
              className="form-control"
              id="cni"
              placeholder="Entrer le numéro CNI"
              value={cnib}
              onChange={(e) => setCnib(e.target.value)}
              required
            />
            <div className="invalid-feedback">Veuillez entrer le numéro CNI</div>
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Entrer l’adresse mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className="invalid-feedback">Veuillez entrer l’adresse mail</div>
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">Téléphone</label>
            <input
              type="number"
              className="form-control"
              id="phone"
              placeholder="Entrer le numéro de téléphone"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              required
            />
            <div className="invalid-feedback">Veuillez entrer le numéro de téléphone</div>
          </div>
          <div className="modal-footer d-flex justify-content-start border-0">
            <button
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              ref={closeRef}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Fermer
            </button>
            <button
              type="submit"
              className="btn btn-primary"
            >
              Ajouter le membre
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>






      <Modal
        title="Modifier un Membre"
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
      >
        <form onSubmit={handleEditSubmit}>
          <div className="row form-row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Nom</label>
                <input
                  type="text"
                  className="form-control"
                  value={editGroup ? editGroup.nom : ""}
                  onChange={(e) =>
                    setEditGroup({ ...editGroup, nom: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Prenom</label>
                <textarea
                  className="form-control"
                  value={editGroup ? editGroup.description : ""}
                  onChange={(e) =>
                    setEditGroup({ ...editGroup, description: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
          </div>
          {errorMessage && (
            <div className="text-danger mb-3">{errorMessage}</div>
          )}
          <button type="submit" className="btn btn-primary w-100">
            Enregistrer
          </button>
        </form>
      </Modal>

      <Modal
        title="Voir le Groupe"
        visible={viewModalVisible}
        onCancel={() => setViewModalVisible(false)}
        footer={null}
      >
        {viewGroup && (
          <div>
            <p><strong>Nom: </strong>{viewGroup.nom}</p>
            <p><strong>Description: </strong>{viewGroup.description}</p>
            <p><strong>Date d'ajout: </strong>{new Date(viewGroup.created).toLocaleDateString("fr-FR")} {new Date(viewGroup.created).toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })}</p>          </div>
        )}
      </Modal>

      <Modal
        title="Confirmation de suppression"
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setDeleteModalVisible(false)}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleDelete(deleteGroup.id)}>
            Supprimer
          </Button>,
        ]}
      >
        <p>Êtes-vous sûr de vouloir supprimer ce groupe ?</p>
      </Modal>
    </>
  );
};

export default Membre;
