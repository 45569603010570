import React from "react";
import ReactDOM from "react-dom/client";

// boostrap
import 'bootstrap/dist/css/bootstrap.min.css';
//import './assets/css/app.css';
// import './assets/css/select2.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/feathericon.min.css';
// import './assets/js/select2.min.js';
// import './assets/js/script.js';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
//fontawesome
import "react-image-lightbox/style.css";
import "react-datepicker/dist/react-datepicker.css";
//carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./client/assets/css/owl.carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
//style
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


import $ from "jquery";
import Popper from "popper.js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";
import {AppContextProvider} from "./services/context";

require("./assets/css/feathericon.min.css");
require("./assets/js/feather.min.js");
 require("./assets/plugins/fontawesome/css/fontawesome.min.css");
require("./assets/plugins/fontawesome/css/all.min.css");
require("./assets/plugins/fontawesome/js/fontawesome.min.js");
require("./assets/css/font-awesome.min.css");
require("./assets/css/custom.css");


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <AppContextProvider>
        <BrowserRouter>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </BrowserRouter>
    </AppContextProvider>
);
//serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
