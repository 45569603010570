import "./App.css";
import { Route, Routes } from "react-router-dom";
//import Dashboard from "./Dashboard";
import { useContext } from "react";
import { AppContext } from "./services/context";
import { routes } from "./services/routes";
import ProtectedRoute from "./components/componentsmedicsoft/ProtectedRoute";

import Header from "./components/header";
//import Dashboard from "./components/dashboard/index";
import Dashboard from "./Dashboard";
import Clients from "./components/clients";
import Doctors from "./components/groupes";
import Reviews from "./components/reviews";
import Settings from "./components/settings";
import Profile from "./components/profile/Profile";
import Login from "./components/login";
import Register from "./components/register";
import ForgotPassword from "./components/forgotpassword";
import Lockscreen from "./components/lockscreen";
import FormVertical from "./components/formvertical";
import FormMask from "./components/formask";
import FormValidation from "./components/formvalidation";
import Components from "./components/component";
import Groupes from "./components/groupes";


function App() {
    const authCtx = useContext(AppContext);
     const { user, onUserChange } = authCtx;

  const createRoute = ({ element, childrens, roles = [], ...route }) => {
    const Component = element
    element = roles.length > 0 ? <ProtectedRoute isAllowed={user.isAuth} redirectPath= "/"><Dashboard /></ProtectedRoute> : <Component />;
    return (

      <Route key={route.path} {...route} element={element}>
        {childrens && childrens.map(createRoute)}
      </Route>
    );
  };
  return (
    <div className="row">
      <Routes>
        <Route path="/" element={<Login />} />
        {
          user.isAuth ? (
            <Route path="/dashboard/*" element={<ProtectedRoute isAllowed={user.isAuth} redirectPath= "/"><Dashboard /></ProtectedRoute>} />
          ) : (
            <Route path="/*" element={<Login />} />
          )
        }
      </Routes>
    </div>
  );
}
export default App;
