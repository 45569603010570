import React from "react";
import { Table } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import Dropdown from 'react-bootstrap/Dropdown';
import MessageSimple from "../../components/dashboard/MessageSimple";
import "bootstrap-daterangepicker/daterangepicker.css";

import { Link } from "react-router-dom";

const  MessageList = () => {
  const data = [
 
  ];
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      sorter: (a, b) => a.PatientName.length - b.PatientName.length,
    },
    {
        title: "Groupe",
        dataIndex: "PatientName",
        sorter: (a, b) => a.PatientName.length - b.PatientName.length,
      },
    {
      title: "Heure",
      dataIndex: "Specialities",
      render: (text, record) => (
        <>
       
          <Link to="/components/profile">{text}</Link>
        </>
      ),
      sorter: (a, b) => a.Specialities.length - b.Specialities.length,
    },
    {
        title: "Date",
        dataIndex: "Specialities",
        render: (text, record) => (
          <>
            
            <Link to="">{text}</Link>
          </>
        ),
        sorter: (a, b) => a.Specialities.length - b.Specialities.length,
      },
      {
        title: "Statut",
        dataIndex: "Statut",
        render: (text, record) => (
          <>
            
            <Link to="">{text}</Link>
          </>
        ),
        sorter: (a, b) => a.Specialities.length - b.Specialities.length,
      },
  
    {
      title: "Action",
      className: "text-end",
      dataIndex: "",
      render: () => (
        <div className="text-end">
        <a
            href="#"
            className="me-1 btn btn-sm bg-primary-light "
            data-bs-toggle="modal"
            data-bs-target="#voir_specialities_details"
          >
            <i className="fe fe-eye"></i> Voir
          </a>
          <a
            href="#"
            className="me-1 btn btn-sm bg-success-light "
            data-bs-toggle="modal"
            data-bs-target="#edit_specialities_details"
          >
            <i className="fe fe-pencil"></i> Editer
          </a>
          
          <a
            href="#"
            className="me-1 btn btn-sm bg-danger-light"
            data-bs-toggle="modal"
            data-bs-target="#delete_modal"
          >
            <i className="fe fe-trash"></i> Supprimer
          </a>
        </div>
      ),
      sorter: (a, b) => a.length - b.length,
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7 col-aut0">
                <h3 className="page-title">Messages</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active">Message</li>
                </ul>
              </div>
          
              <div className="col-sm-5 col">

              <div className="col-sm-8 col">
              <Dropdown>
                    <Dropdown.Toggle variant="primary float-end mt-2" id="dropdown-basic">
                    Envoyer
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item >
                      <Link to="/dashboard/form-vertical"> En Groupe  </Link> 
                      </Dropdown.Item>
                      
                   
                      <Dropdown.Item>
                      <Link to="/dashboard/message-individuel">Individuel </Link>        
                    </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                 </div>

                  <div >

</div>

         </div>

        

    
             
            </div>
          </div>
          {/* /Page Header */}
         {/* ------------ */}

        


         <div className="row">
         <MessageSimple />
            </div>

        
            


           

         {/* ------------------ */}

          
        </div>
      </div>
      

     
             


     
       
    </>
  );
};

export default MessageList;
{/* <Route path="/messageformulaire" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><MessageFormulaire /> </ProtectedRoute>} /> */}
