import React, { useEffect, useState } from "react";
import axios from 'axios';
import { notification } from 'antd';

const VerticalForm = () => {
    const [showRepeat, setShowRepeat] = useState(false);
    const toggleRepeat = () => {
        setShowRepeat(!showRepeat);
    };
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedGroupe, setSelectedGroupe] = useState("");
    const [sendDate, setSendDate] = useState('');
    const [sendTime, setSendTime] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await axios.get("https://www.staging.messagelinkpro.com/messagelinkpro/groupe/liste");
                setGroups(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des groupes:", error);
            }
        };
        fetchGroups();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://www.staging.messagelinkpro.com/messagelinkpro/external-api/message/send/programme/uneFois/groupe', {
                groupeId: selectedGroup,
                sendDate,
                sendTime,
                message,
            });
            notification.success({
                message: 'Succès',
                description: 'Message programmé envoyé avec succès',
            });
        } catch (error) {
            notification.error({
                message: 'Erreur',
                description: 'Erreur lors de l\'envoi du message programmé',
            });
        }
    };

    const envoyerSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://www.staging.messagelinkpro.com/messagelinkpro/external-api/message/send/groupe/simple', {
                groupeId: selectedGroupe,
                message,
            });
            notification.success({
                message: 'Succès',
                description: 'Message instantané envoyé avec succès',
            });
        } catch (error) {
            notification.error({
                message: 'Erreur',
                description: 'Erreur lors de l\'envoi du message instantané',
            });
        }
    };

    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Message programmé groupé</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Groupe <span className="text-danger">*</span></label>
                                                    <select
                                                        className="form-control"
                                                        value={selectedGroup}
                                                        onChange={(e) => setSelectedGroup(e.target.value)}
                                                    >
                                                        <option value="">Sélectionner un groupe</option>
                                                        {groups.map((groupeId) => (
                                                            <option key={groupeId.id} value={groupeId.id}>
                                                                {groupeId.nom}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Date:</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={sendDate}
                                                        onChange={(e) => setSendDate(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Heure:</label>
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        value={sendTime}
                                                        onChange={(e) => setSendTime(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Message:</label>
                                                    <textarea
                                                        rows="4"
                                                        className="form-control h-75"
                                                        placeholder="Entrer un message"
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    />
                                                </div>
                                                <div className="">
                                                    <button type="button" className="btn btn-primary" onClick={toggleRepeat}>
                                                        {showRepeat ? 'Non Répété' : ' Répété'}
                                                    </button>
                                                </div>
                                            </div>
                                            {showRepeat && (
                                                <div className="col-md-6">
                                                    <h4 className="card-title">Repéter</h4>
                                                    <div className="row text-center">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <input type="radio" className="radio" name="radio" />
                                                                <label>Par jours:</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <input type="radio" className="radio" name="radio" />
                                                                <label> weekend:</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <input type="radio" className="radio" name="radio" />
                                                                <label>Par mois</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Date de debut:</label>
                                                                <input type="date" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Date de fin:</label>
                                                                <input type="date" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Heure de debut:</label>
                                                                <input type="time" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="text-end">
                                            <button type="submit" className="btn btn-primary">
                                                Envoyer
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Message instantané groupé</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={envoyerSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Groupe <span className="text-danger">*</span></label>
                                                    <select
                                                        className="form-control"
                                                        value={selectedGroupe}
                                                        onChange={(e) => setSelectedGroupe(e.target.value)}
                                                    >
                                                        <option value="">Sélectionner un groupe</option>
                                                        {groups.map((groupeId) => (
                                                            <option key={groupeId.id} value={groupeId.id}>
                                                                {groupeId.nom}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Message:</label>
                                                    <textarea
                                                        rows="4"
                                                        className="form-control h-75"
                                                        placeholder="Entrer un message"
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button type="submit" className="btn btn-primary">
                                                Envoyer
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VerticalForm;
