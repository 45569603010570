import React, { useState } from 'react'; 
import axios from 'axios';
import { notification } from 'antd';
import EmailForm from './emailindividu';

const EmailInd = () => {
    const [email, setEmail] = useState('');
    const [cc, setCc] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [dateTime, setDateTime] = useState('');
    const [emailError, setEmailError] = useState('');
    const [ccError, setCcError] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [dateTimeError, setDateTimeError] = useState('');

    const openNotificationWithIcon = (type, description) => {
        notification[type]({
            message: type === 'success' ? 'Succès' : 'Erreur',
            description,
        });
    };

    const handleFileChange = (e) => {
        setAttachments(e.target.files);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        let hasError = false;

        // Réinitialisation des messages d'erreurs
        setEmailError('');
        setCcError('');
        setSubjectError('');
        setMessageError('');
        setDateTimeError('');
        
        // Validation des champs
        if (!email) {
            setEmailError('Veuillez entrer un email.');
            hasError = true;
        }
        
        if (!subject) {
            setSubjectError('Veuillez entrer un sujet.');
            hasError = true;
        }

        if (!message) {
            setMessageError('Veuillez entrer un message.');
            hasError = true;
        }

        if (!dateTime) {
            setDateTimeError('Veuillez entrer une date et une heure.');
            hasError = true;
        }

        // Si des erreurs existent, ne pas soumettre le formulaire
        if (hasError) {
            return;
        }

        // Préparation des données
        const formData = new FormData();
        formData.append('to', email);
        formData.append('cc', cc);
        formData.append('subject', subject);
        formData.append('text', message);
        formData.append('dateTime', dateTime);

        // Ajout des fichiers sélectionnés en pièces jointes
        if (attachments.length > 0) {
            Array.from(attachments).forEach((file) => {
                formData.append('attachmentPaths', file);
            });
        }

        // Envoi de la requête
        try {
            const response = await axios.post('http://www.staging.messagelinkpro.com/messagelinkpro/external-api/email/programme',
 formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Email envoyé :', response.data);
            openNotificationWithIcon('success', 'L\'email a été envoyé avec succès.');
        } catch (error) {
            console.error('Erreur lors de l\'envoi de l\'email :', error);
            openNotificationWithIcon('error', 'Une erreur s\'est produite lors de l\'envoi de l\'email.');
        }
    };

    return (
        <>
            {/* Page Wrapper */}
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Email programmé</h4>
                                </div>
                                <div className="card-body">
                                   
                                <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label>Email du destinataire :</label>
                <input
                    type="email"
                    className={`form-control ${emailError ? 'is-invalid' : ''}`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && <div className="invalid-feedback">{emailError}</div>}
            </div>

            
            <div className="form-group">
                <label>Objet :</label>
                <input
                    type="text"
                    className={`form-control ${subjectError ? 'is-invalid' : ''}`}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />
                {subjectError && <div className="invalid-feedback">{subjectError}</div>}
            </div>

            <div className="form-group">
                <label>Message :</label>
                <textarea
                    rows="4"
                    className={`form-control ${messageError ? 'is-invalid' : ''}`}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                {messageError && <div className="invalid-feedback">{messageError}</div>}
            </div>
            <div className="form-group">
                <label>CC :</label>
                <input
                    type="email"
                    className={`form-control ${ccError ? 'is-invalid' : ''}`}
                    value={cc}
                    onChange={(e) => setCc(e.target.value)}
                />
                {ccError && <div className="invalid-feedback">{ccError}</div>}
            </div>

            <div className="form-group">
                <label>Date et Heure :</label>
                <input
                    type="datetime-local"
                    className={`form-control ${dateTimeError ? 'is-invalid' : ''}`}
                    value={dateTime}
                    onChange={(e) => setDateTime(e.target.value)}
                />
                {dateTimeError && <div className="invalid-feedback">{dateTimeError}</div>}
            </div>

            <div className="form-group">
                <label>Pièces jointes :</label>
                <input
                    type="file"
                    multiple
                    className="form-control"
                    onChange={handleFileChange}
                />
            </div>

            <div className="text-end">
                <button type="submit" className="btn btn-primary">
                    Envoyer l'email
                </button>
            </div>
        </form>

                                </div>
                            </div>
                        </div>

                        {/* ----------------------------------------- */}

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Email instantané</h4>
                                </div>
                                <div className="card-body">
                                    <EmailForm/>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Form Vertical */}
                </div>
            </div>
            {/* /Page Wrapper */}
        </>
    );
}
export default EmailInd;
