import React, {useContext, useState, useEffect } from "react";
import { Table, Modal, Button, notification } from "antd"; // Ajoutez notification ici
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import {AppContext} from "../../services/context";
import { FileTextOutlined } from "@ant-design/icons";



const EmailListe = () => {

  const authCtx = useContext(AppContext);
  const {user, onUserChange} = authCtx;

  const [submittedData, setSubmittedData] = useState([]);
  const [viewGroup, setViewGroup] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [annulerGroup, setAnnulerGroup] = useState(null);
  const [annulerModalVisible, setAnnulerModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const imageUrl =""
  ? `http://localhost:8083/images/`
  : "default-image.jpg"; 
  
  const url = "http://localhost:8083/";

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(url+"messagelinkpro/external-api/email/liste",
          {
            headers: {
              Authorization: `Bearer ${user.token}`
            },
          }
        );
        console.log("Response data:", response.data);
        setSubmittedData(Array.isArray(response.data) ? response.data : response.data.messages || []);
      } catch (error) {
        console.error("Error fetching messages:", error);
        notification.error({
          message: "Erreur de chargement",
          description: "Impossible de charger les messages. Veuillez réessayer.",
        });
      }
    };
    fetchMessages();
  }, []);
  
  // const handleDelete = async (id) => {
  //   try {
  //     await axios.delete(`http://www.staging.messagelinkpro.com/messagelinkpro/external-api/message/delete/${id}`);
  //     setSubmittedData(submittedData.filter((message) => message.id !== id));
  //     setDeleteModalVisible(false);
  //     setDeleteGroup(null);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     notification.error({
  //       message: "Erreur de suppression",
  //       description: "Erreur lors de la suppression du message. Veuillez réessayer.",
  //     });
  //   }
  // };

  const handleCancel = async (sid) => {
    try {
      await axios.delete(`http://www.staging.messagelinkpro.com/messagelinkpro/external-api/email/annuler/${sid}`);
      setSubmittedData(submittedData.filter((message) => message.sid !== sid));
      setAnnulerModalVisible(false);
      setAnnulerGroup(null);
    } catch (error) {
      console.error("Error:", error);
      notification.error({
        message: "Erreur d'annulation",
        description: "Erreur lors de l'annulation du message. Veuillez réessayer.",
      });
    }
  };

  const handleView = (message) => {
    setViewGroup(message);
    setViewModalVisible(true);
  };

  const handleDeleteConfirm = (message) => {
    setDeleteGroup(message);
    setDeleteModalVisible(true);
  };

  const handleAnnuleConfirm = (message) => {
    setAnnulerGroup(message);
    setAnnulerModalVisible(true);
  };

  const getStatusStyle = (status) => {
    const styles = {
      accepted: { backgroundColor: "#008000", color: "white" },
      delivered: { backgroundColor: "#008000", color: "white" },
      failed: { backgroundColor: "#FFFF00", color: "white" },
      canceled: { backgroundColor: "#FF0000", color: "black" },
      undelivered: { backgroundColor: "#FF0000", color: "white" },
    };
    return styles[status] || { backgroundColor: "#ffffff", color: "black" };
  };

  const filteredData = Array.isArray(submittedData) ? submittedData.filter((message) => {
    const from = message.from?.toLowerCase() || "";
    const subject = message.subject?.toLowerCase() || "";
    const text = message.text?.toLowerCase() || "";

    return (
      from.includes(searchText.toLowerCase()) ||
      subject.includes(searchText.toLowerCase()) ||
      text.includes(searchText.toLowerCase())
    );
  }) : [];
  
  const sortedFilteredData = [...filteredData].sort((a, b) => new Date(b.created) - new Date(a.created));

  return (
    <>
      <div className="col-sm-7 col-aut0">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <div className="d-inline-block">
              <div className="top-nav-search">
                <form>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Rechercher par..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <button className="btn" type="submit">
                    <i className="fa fa-search"/>
                  </button>
                </form>
              </div>
            </div>
          </li>
        </ul>
      </div> 
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              {/* <div className="card-header">
                <h4 className="card-title">Liste des mails instantanés</h4>
              </div> */}
              <div className="table-responsive">
                <Table
                  columns={[
                    {
                      title: "Ordre",
                      render: (text, record, index) => index + 1,
                    },
                    // {
                    //   title: "De",
                    //   dataIndex: "from",
                    // },
                    {
                      title: "Objet",
                      dataIndex: "subject",
                    },
                    {
                      title: "Contenu",
                      dataIndex: "text",
                    },
                    // {
                    //   title: "Fichiers",
                    //   dataIndex: "attachmentPaths",
                    
                    // },
                    {
                      title: "Fichiers",
                      dataIndex: "attachmentPaths",
                      key: "attachmentPaths",
                      render: (attachmentPaths) => {
                        // Vérifier si attachmentPaths est un tableau
                        if (!Array.isArray(attachmentPaths)) {
                          attachmentPaths = [attachmentPaths];
                        }
                    
                        return (
                          <div>
                            {attachmentPaths.map((path, index) => {
                              const fileUrl = `http://localhost:8083/images/${path}`;
                              const fileExtension = path.split('.').pop().toLowerCase();
                    
                              if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
                                // Afficher l'image
                                return (
                                  <img
                                    key={index}
                                    src={fileUrl}
                                    alt="no image"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                      marginRight: "10px"
                                    }}
                                  />
                                );
                              } else if (fileExtension === 'pdf') {
                                // Afficher le lien vers le PDF
                                return (
                                  <a
                                    key={index}
                                    href={fileUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ display: "block", marginBottom: "5px" }}
                                  >
                                    <FileTextOutlined
                                      style={{ fontSize: "24px", color: "#1890ff" }}
                                    />
                                  </a>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </div>
                        );
                      },
                    },
                    
                    
                    // {
                    //   title: "Statut",
                    //   dataIndex: "status",
                    //   render: (status) => {
                    //     const { backgroundColor, color } = getStatusStyle(status);
                    //     return (
                    //       <span style={{ backgroundColor, color, padding: '5px 10px', borderRadius: '4px' }}>
                    //         {status}
                    //       </span>
                    //     );
                    //   },
                    // },
                    {
                      title: "Date d'envoi",
                      dataIndex: 'created',
                      render: (text) => {
                        if (!text) return "";
                        const date = new Date(text);
                        return `${date.toLocaleDateString('fr-FR')} ${date.toLocaleTimeString('fr-FR')}`;
                      },
                    },
                    {
                      title: "Action",
                      className: "text-end",
                      render: (text, record) => (
                        <div className="text-end">
                          <a href="#" className="me-1 btn btn-sm bg-primary-light" onClick={() => handleView(record)}>
                            <i className="fe fe-eye"></i> Voir
                          </a>
                          {record.status === "scheduled" && (
                            <a href="#" className="me-1 btn btn-sm bg-warning-light" onClick={() => handleAnnuleConfirm(record)}>
                              <i className="fe fe-eye"></i> Annuler
                            </a>
                          )}
                          {(record.status === "accepted" || record.status === "delivered" || record.status === "failed" || record.status === "canceled" || record.status === "undelivered") && (
                            <a
                              href="#"
                              className="me-1 btn btn-sm bg-danger-light"
                              onClick={() => handleDeleteConfirm(record)}
                              style={{ backgroundColor: getStatusStyle(record.status).backgroundColor }}
                            >
                              <i className="fe fe-trash"></i> Supprimer
                            </a>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  dataSource={sortedFilteredData}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal title="Voir le Message" open={viewModalVisible} onCancel={() => setViewModalVisible(false)} footer={null}>
        {viewGroup && (
          <div>
            <p><strong>De: </strong>{viewGroup.from}</p>
            <p><strong>Objet: </strong>{viewGroup.subject}</p>
            <p><strong>Message: </strong>{viewGroup.text}</p>
            <p><strong>Statut: </strong>{viewGroup.status}</p>
            <p><strong>Date: </strong>{new Date(viewGroup.created).toLocaleString()}</p>
            {/* Ajoutez d'autres champs si nécessaire */}
          </div>
        )}
      </Modal>

      <Modal
        title="Confirmation de suppression"
        open={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setDeleteModalVisible(false)}>
            Annuler
          </Button>,
          <Button key="delete" type="primary" onClick={() => handleDelete(deleteGroup.id)}>
            Supprimer
          </Button>,
        ]}
      >
        <p>Êtes-vous sûr de vouloir supprimer ce message ?</p>
      </Modal>

      <Modal
        title="Confirmation d'annulation"
        open={annulerModalVisible}
        onCancel={() => setAnnulerModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setAnnulerModalVisible(false)}>
            Annuler
          </Button>,
          <Button key="cancel" type="primary" onClick={() => handleCancel(annulerGroup.sid)}>
            Annuler
          </Button>,
        ]}
      >
        <p>Êtes-vous sûr de vouloir annuler ce message ?</p>
      </Modal>
    </>
  );
};

export default EmailListe;
