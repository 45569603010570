import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios"; 

const StatusChart = () => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "line",
      height: "100%",
      width: "100%",
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: "Clients",
        data: [],
      },
      {
        name: "Groupes",
        data: [],
      },
    ],
    xaxis: {
      categories: [], 
    },
    colors: ["#1b5a90", "#ff9d00"],
    stroke: {
      width: 2,
      curve: "smooth",
    },
    markers: {
      size: 3,
    },
    grid: {
      show: true,
      borderColor: "#ebebeb",
      strokeDashArray: 2,
    },
    tooltip: {
      theme: "dark",
    },
  });

  
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const clientsResponse = await axios.get("https://www.staging.messagelinkpro.com/messagelinkpro/external-api/statistics/clients-par-month");
        const groupesResponse = await axios.get("https://www.staging.messagelinkpro.com/messagelinkpro/external-api/statistics/groupes-par-month");
  
        const clientsData = clientsResponse.data;
        const groupesData = groupesResponse.data;
  
        
        const allMonths = [...new Set([...Object.keys(clientsData), ...Object.keys(groupesData)])].sort();
  
        
        const clientsSeries = allMonths.map((month) => ({
          x: month,
          y: clientsData[month] || 0,
        }));
        const groupesSeries = allMonths.map((month) => ({
          x: month,
          y: groupesData[month] || 0,
        }));
  
        // Update the chart options
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            categories: allMonths,
          },
          series: [
            { name: "Clients", data: clientsSeries },
            { name: "Groupes", data: groupesSeries },
          ],
        }));
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
  
    fetchData();
  }, []);
  

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        type="line"
        height="300"
      />
    </div>
  );
};

export default StatusChart;
