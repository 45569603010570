import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

const LineChart = () => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://www.staging.messagelinkpro.com/messagelinkpro/external-api/statistics/messages-par-year')
      .then((response) => {
        const data = response.data;
        console.log('Données de l\'API:', data); 

        const formattedData = Object.entries(data).map(([allMonths, count]) => ({
          allMonths,
          revenue: count,
        }));
        console.log('Données formatées:', formattedData); 

        setChartData(formattedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des données :', error);
        setLoading(false);
      });
  }, []);

  const chartOptions = {
    chart: {
      type: 'area',
      stacked: false,
      height: '100%',
      width: '100%',
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: 'Messages',
        data: chartData.map((data) => data.revenue),
      },
    ],
    xaxis: {
      categories: chartData.map((data) => data.allMonths),
    },
    colors: ['#1b5a90'],
    stroke: {
      width: 2,
    },
    fill: {
      opacity: 0.5,
    },
    grid: {
      show: true,
      borderColor: '#ebebeb',
      strokeDashArray: 2,
    },
    markers: {
      size: 3,
    },
    tooltip: {
      theme: 'dark',
    },
  };

  if (loading) {
    return <p>Chargement...</p>;
  }

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        type="area"
        height="300"
      />
    </div>
  );
};

export default LineChart;
