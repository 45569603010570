import React, { useEffect, useState } from "react"; 
import axios from 'axios';
import { notification } from 'antd';

const Emailgroupe = () => {
    const [showRepeat, setShowRepeat] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedGroupe, setSelectedGroupe] = useState("");
    const [sendDate, setSendDate] = useState('');
    const [sendTime, setSendTime] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [attachments, setAttachments] = useState(null);
    const [cc, setCc] = useState('');
    const [ccError, setCcError] = useState('');

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await axios.get("http://www.staging.messagelinkpro.com/messagelinkpro/external-api/groupe/liste");
                setGroups(response.data);
            } catch (error) {
                notification.error({
                    message: 'Erreur',
                    description: 'Erreur lors de la récupération des groupes.',
                });
                console.error("Erreur lors de la récupération des groupes:", error);
            }
        };
        fetchGroups();
    }, []);

    const handleFileChange = (e) => {
        setAttachments(e.target.files);
    };

    // Combine date and time into a single ISO string
    const combineDateAndTime = (date, time) => {
        if (!date || !time) return null;
        const combined = new Date(`${date}T${time}`);
        return combined.toISOString(); // Format ISO pour envoyer au backend
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!selectedGroup || !subject || !message) {
            notification.error({
                message: 'Erreur',
                description: 'Tous les champs obligatoires doivent être remplis.',
            });
            return;
        }

        // Validate CC field
        if (cc && !/\S+@\S+\.\S+/.test(cc)) {
            setCcError('Adresse e-mail invalide');
            return;
        } else {
            setCcError('');
        }

        const formData = new FormData();
        formData.append('groupeId', selectedGroup);
        const combinedDateTime = combineDateAndTime(sendDate, sendTime);
        if (combinedDateTime) formData.append('sendDateTime', combinedDateTime);
        formData.append('subject', subject);
        formData.append('message', message);

        if (cc) {
            formData.append('cc', cc);
        }

        if (attachments) {
            for (let i = 0; i < attachments.length; i++) {
                formData.append('attachments', attachments[i]);
            }
        }

        try {
            await axios.post(
                'http://www.staging.messagelinkpro.com/messagelinkpro/external-api/email/programme',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            notification.success({
                message: 'Succès',
                description: 'Message programmé envoyé avec succès',
            });
            resetForm();
        } catch (error) {
            notification.error({
                message: 'Erreur',
                description: 'Erreur lors de l\'envoi du message programmé',
            });
            console.error(error);
        }
    };

    const envoyerSubmit = async (e) => {
        e.preventDefault();
        
        if (!selectedGroupe || !subject || !message) {
            notification.error({
                message: 'Erreur',
                description: 'Tous les champs obligatoires doivent être remplis.',
            });
            return;
        }

        // Validate CC field
        if (cc && !/\S+@\S+\.\S+/.test(cc)) {
            setCcError('Adresse e-mail invalide');
            return;
        } else {
            setCcError('');
        }

        const formData = new FormData();
        formData.append('groupeId', selectedGroupe);
        formData.append('subject', subject);
        formData.append('message', message);
        
        if (cc) {
            formData.append('cc', cc);
        }

        if (attachments) {
            for (let i = 0; i < attachments.length; i++) {
                formData.append('attachments', attachments[i]);
            }
        }

        try {
            await axios.post(
                'http://www.staging.messagelinkpro.com/messagelinkpro/external-api/email/envoye',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            notification.success({
                message: 'Succès',
                description: 'Message instantané envoyé avec succès',
            });
            resetForm();
        } catch (error) {
            notification.error({
                message: 'Erreur',
                description: 'Erreur lors de l\'envoi du message instantané',
            });
            console.error(error);
        }
    };

    const resetForm = () => {
        setSelectedGroup("");
        setSelectedGroupe("");
        setSendDate("");
        setSendTime("");
        setSubject("");
        setMessage("");
        setCc("");
        setAttachments(null);
    };

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <div className="row">
                    {/* Formulaire de Message programmé groupé */}
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Message programmé groupé</h4>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* Champ Groupe */}
                                            <div className="form-group">
                                                <label>Groupe <span className="text-danger">*</span></label>
                                                <select
                                                    className="form-control"
                                                    value={selectedGroup}
                                                    onChange={(e) => setSelectedGroup(e.target.value)}
                                                >
                                                    <option value="">Sélectionner un groupe</option>
                                                    {groups.map((groupeId) => (
                                                        <option key={groupeId.id} value={groupeId.id}>
                                                            {groupeId.nom}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* Champ Objet */}
                                            <div className="form-group">
                                                <label>Objet <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Entrer l'objet"
                                                    value={subject}
                                                    onChange={(e) => setSubject(e.target.value)}
                                                />
                                            </div>
                                            {/* Champ CC */}
                                            <div className="form-group">
                                                <label>CC :</label>
                                                <input
                                                    type="email"
                                                    className={`form-control ${ccError ? 'is-invalid' : ''}`}
                                                    value={cc}
                                                    onChange={(e) => setCc(e.target.value)}
                                                />
                                                {ccError && <div className="invalid-feedback">{ccError}</div>}
                                            </div>
                                            {/* Champ Date */}
                                            <div className="form-group">
                                                <label>Date:</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={sendDate}
                                                    onChange={(e) => setSendDate(e.target.value)}
                                                />
                                            </div>
                                            {/* Champ Heure */}
                                            <div className="form-group">
                                                <label>Heure:</label>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    value={sendTime}
                                                    onChange={(e) => setSendTime(e.target.value)}
                                                />
                                            </div>
                                            {/* Champ Message */}
                                            <div className="form-group">
                                                <label>Message:</label>
                                                <textarea
                                                    rows="4"
                                                    className="form-control h-75"
                                                    placeholder="Entrer un message"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />
                                            </div>
                                            {/* Champ Attachment */}
                                            <div className="form-group">
                                                <label>Pièces jointes (PDF ou Image):</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    accept=".pdf, image/*"
                                                    multiple
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                            {/* Bouton Répéter */}
                                            <div className="">
                                                <button type="button" className="btn btn-primary" onClick={() => setShowRepeat(!showRepeat)}>
                                                    {showRepeat ? 'Non Répété' : 'Répété'}
                                                </button>
                                            </div>
                                        </div>
                                        {/* Section Répétition */}
                                        {showRepeat && (
                                            <div className="col-md-12">
                                                <h4 className="card-title mt-3">Répéter</h4>
                                                <div className="row text-center">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <input type="radio" className="radio" name="repeat" value="daily" />
                                                            <label> Quotidien </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <input type="radio" className="radio" name="repeat" value="weekly" />
                                                            <label> Hebdomadaire </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <input type="radio" className="radio" name="repeat" value="monthly" />
                                                            <label> Mensuel </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* Bouton d'Envoi */}
                                    <button type="submit" className="btn btn-primary">
                                        Programmez le message
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* Formulaire de Message instantané groupé */}
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Message instantané groupé</h4>
                            </div>
                            <div className="card-body">
                                <form onSubmit={envoyerSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* Champ Groupe */}
                                            <div className="form-group">
                                                <label>Groupe <span className="text-danger">*</span></label>
                                                <select
                                                    className="form-control"
                                                    value={selectedGroupe}
                                                    onChange={(e) => setSelectedGroupe(e.target.value)}
                                                >
                                                    <option value="">Sélectionner un groupe</option>
                                                    {groups.map((groupeId) => (
                                                        <option key={groupeId.id} value={groupeId.id}>
                                                            {groupeId.nom}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* Champ Objet */}
                                            <div className="form-group">
                                                <label>Objet <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Entrer l'objet"
                                                    value={subject}
                                                    onChange={(e) => setSubject(e.target.value)}
                                                />
                                            </div>
                                            {/* Champ CC */}
                                            <div className="form-group">
                                                <label>CC :</label>
                                                <input
                                                    type="email"
                                                    className={`form-control ${ccError ? 'is-invalid' : ''}`}
                                                    value={cc}
                                                    onChange={(e) => setCc(e.target.value)}
                                                />
                                                {ccError && <div className="invalid-feedback">{ccError}</div>}
                                            </div>
                                            {/* Champ Message */}
                                            <div className="form-group">
                                                <label>Message:</label>
                                                <textarea
                                                    rows="4"
                                                    className="form-control h-75"
                                                    placeholder="Entrer un message"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />
                                            </div>
                                            {/* Champ Attachment */}
                                            <div className="form-group">
                                                <label>Pièces jointes (PDF ou Image):</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    accept=".pdf, image/*"
                                                    multiple
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Bouton d'Envoi */}
                                    <button type="submit" className="btn btn-primary">
                                        Envoyez le message
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Emailgroupe;
