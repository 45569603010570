import React, { useContext, useEffect, useState } from "react";
import { logoWhite } from "../imagepath";
import logo from "../.././assets/img/suivivital.png";
import intersect from "../.././assets/img/intersect.png";
import pwd from "../.././assets/img/pwd.png";
import { Link, useNavigate } from "react-router-dom";
import requestUser from "../../services/requestUser";
import { apiUser } from "../../services/api";
import { AppContext } from "../../services/context";
import FormNotify from "../componentsmedicsoft/FormNotify";

const Login = () => {
  const authCtx = useContext(AppContext);
  const { user, onUserChange } = authCtx;
  const navigate = useNavigate();
  const [inputType, setInputType] = useState("password");
  const [loginFail, setLoginFail] = useState(false);
  const [login, setLogin] = useState({
    username: "",
    password: "",
  });
  const [isSend,setIsSend] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    isAuth();
  }, [user.isAuth]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoginFail(false);
    requestUser
        .post(apiUser.login, login)
        .then((res) => {
          //console.log(res.data);
          onUserChange({
            isAuth: true,
            type: "",
            name: res.data.firstname + " " + res.data.lastname,
            organisation: res.data.roles[0].organisation,
            organisationRef: Object.keys(res.data.organisation)[0],
            organisations: res.data.organisation,
            profile: "",
            roles: res.data.roles,
            token: res.data.accessToken,
            refreshToken: res.data.refreshToken,
            cni: "",
          });
          isAuth();
        })
        .catch((error) => {
          setLoginFail(true);
          console.log(error);
        });
  };

  const isAuth = () => {

    if (user.isAuth === true && user.token != null && user.token !== "") {
      console.log(`connexion reussi, isAuth: ${user}`);
      console.log(user);

      return navigate("/dashboard/");
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };
  const restPassword = (e) => {
    e.preventDefault();
    const data = { email: login.username };
    console.log(data);
    requestUser
        .post(apiUser.forget + "?email=" + login.username)
        .then((res) => {
          console.log(res.data);
          setIsSend(true)
        })
        .catch((error) => {
          console.log(error);
        });
  };
  return (
    <>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <div className="loginbox">
              <div className="login-left">
                <img className="img-fluid" src={logoWhite} alt="Logo" />
              </div>
              <div className="login-right">
                <div className="login-right-wrap">
                  <h1>Se Connecter</h1>
                  <p className="account-subtitle">Accès à votre compte</p>

                  {/* Form */}
                  <form onSubmit={handleSubmit} className="mb-5">
                    {loginFail ? (
                        <div className="mb-3">
                          <FormNotify
                              bg={"danger"}
                              title={""}
                              message={"Nom d’utilisateur ou mot de passe incorrect."}
                          />
                        </div>
                    ) : null}
                    <div className="form-floating mb-4">
                      <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        name="username"
                        value={login.username}
                        onChange={onChange}
                      />
                      <label htmlFor="floatingInput">Email</label>
                    </div>
                    <div class="form-floating mb-4 position-relative">
    <input
        type={passwordVisible ? 'text' : 'password'}
        class="form-control pr-5"
        id="floatingPassword"
        placeholder="Password"
        name="password"
        value={login.password}
        onChange={onChange}
    />
    <i class={passwordVisible ? "fas fa-eye-slash position-absolute end-0 top-50 translate-middle-y px-2" : "fas fa-eye position-absolute end-0 top-50 translate-middle-y px-2"} onClick={togglePasswordVisibility}></i>
    <label for="floatingPassword">Mots de passe</label>
</div>

                    <div class="form-floating mb-4 position-relative">
    
</div>
                    <div className="checkbox mb-4 position-relative">
                      <label className="text-small align-middle">
                        <input
                            className="no-height"
                            type="checkbox"
                            value="remember-me"
                        />{" "}
                        Se souvenir de moi
                      </label>
                      <Link
                          to="/forgotpassword"
                          className="text-small link text-deco position-absolute top-0 end-0"
                          data-bs-toggle="modal"
                          data-bs-target="#forgetPassword"
                      >
                        Mot de passe oublié ?
                      </Link>
                    </div>
                    <div className="form-group">
                      <button className="btn btn-primary w-100" type="submit">
                        Se connecter
                      </button>
                    </div>
                  </form>
                  {/* /Form */}

                  <div className="text-center dont-have">
                    Je n'ai pas de compte?{" "}
                    <Link to="/register">S'inscrire</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
