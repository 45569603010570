import React from "react";
import { Table } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import Dropdown from 'react-bootstrap/Dropdown';
import "bootstrap-daterangepicker/daterangepicker.css";

import { Link } from "react-router-dom";
import EmailListe from "./envoismailliste";

const  Email = () => {
 
        
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7 col-aut0">
                <h3 className="page-title">Emails</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active">Email</li>
                </ul>
              </div>
          
              <div className="col-sm-5 col">

              <div className="col-sm-8 col">
              <Dropdown>
                    <Dropdown.Toggle variant="primary float-end mt-2" id="dropdown-basic">
                    Envoyer
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item >
                      <Link to="/dashboard/Envoismailgroupe"> Groupe  </Link> 
                      </Dropdown.Item>
                      
                   
                      <Dropdown.Item>
                      <Link to="/dashboard/EnvoismailInd">Individuel </Link>        
                    </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                 </div>

                  <div >

</div>

         </div>

        

    
             
            </div>
          </div>
          {/* /Page Header */}
         {/* ------------ */}

        


         <div className="row">
         <EmailListe />
            </div>

        
            


           

         {/* ------------------ */}

          
        </div>
      </div>
      

     
             


     
       
    </>
  );
};

export default Email;
{/* <Route path="/messageformulaire" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><MessageFormulaire /> </ProtectedRoute>} /> */}
