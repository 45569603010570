import React, { useState, useContext, useEffect,Switch } from "react";
import { Scrollbars } from "react-custom-scrollbars";
// import FeatherIcon from "feather-icons-react";
import { AppContext } from "./services/context";
import {useLocation, useNavigate} from "react-router-dom";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/componentsmedicsoft/ProtectedRoute";
import Employe from "./pages/Employe";
import Settings from "./pages/Settings";
import {getUser} from "./services/storage";
import Groupes from "./components/groupes";
import Home from "./pages/Home";
import Header from "./components/header";
import userp from "./assets/imgs/user.png";
import requestUser, { URLLVM } from "./services/requestUser";
import { apiUser } from "./services/api";
import GroupesPerso from "./components/groupespersonnes";
import MessageProgr from "./components/message";
import VerticalForm from "./components/formvertical";
import Profile from "./components/profile/Profile";
import MessageindForm from "./components/messageind";
import Utilisateurs from "./components/utilisateurs";
import SendEmail from "./components/Envoismail";
import MessageSimple from "./components/dashboard/MessageSimple";
import MessageList from "./components/message";
import Clients from "./components/clients";
import Membre from "./components/membres";

import EmailListe from "./components/Envoismail/envoismailliste";
import Emailgroupe from "./components/Envoismail/emailgroupe";
import EmailInd from "./components/Envoismail/emailind";
import Email from "./components/Envoismail";


const Dashboard = () => {

    const authCtx = useContext(AppContext);
    const { user, onUserChange } = authCtx;
    const [org, setOrg] = useState("");
    const [userLocal, setUserLocal] = useState("");
    let navigate = useNavigate();
    const [userImg, setUserImg] = useState(userp);
    const [menu, setMenu] = useState(false);
    const location = useLocation();
    const pathname = location.pathname;

    const { setIsAuth } = useContext(AppContext);
    const [isSideMenu, setSideMenu] = useState("");
    const [isSideMenuNew, setSideMenuNew] = useState("");
    const [isSideMenuNew2, setSideMenuNew2] = useState("");

    const toggleSidebar = (value) => {
        setSideMenu(value);
    };

    const toggleSidebarNew = (value) => {
        setSideMenuNew(value);
    };

    const toggleSidebarNew2 = (value) => {
        setSideMenuNew2(value);
    };

    // eslint-disable-next-line no-unused-vars
    const [isSidebarExpanded, setSidebarExpanded] = useState(false);
    const [isMouseOverSidebar, setMouseOverSidebar] = useState(false);

    useEffect(() => {
    //    
    }, [isMouseOverSidebar,user]);

     const isAuth = () => {
         if (user.isAuth == false || user.token == null || user.token == "") {
             console.log(`connexion échoué, isAuth`);
             console.log(user);

             return navigate("/");
        } else {
             console.log("isAuth true");
        }
     };

    const deconnect = () => {
        deleteUser();
        onUserChange(initialUser);
    };
    const handleMouseEnter = () => {
        setMouseOverSidebar(true);
    };

    const handleMouseLeave = () => {
        setMouseOverSidebar(false);
    };

    return (
        <>
            {/* <!-- Sidebar --> */}
            <div
                className={`sidebar ${isSidebarExpanded ? "" : "hidden"}`}
                id="sidebar"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Scrollbars
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax="95vh"
                    thumbMinSize={30}
                    universal={false}
                    hideTracksWhenNotNeeded={true}
                >
                    <div className="sidebar-inner slimscroll">
                        <div id="sidebar-menu" className="sidebar-menu">
                            <ul>
                                {/* <li className="menu-title">
                                    <span>Menu</span>
                                </li> */}
                                <li className={pathname === "/dashboard" ? "active" : ""}>
                                    <Link to="/dashboard">
                                        <i className="fe fe-home"></i>
                                        <span>Accueil</span>
                                    </Link>
                                </li>
                                {/* <li
                                >
                                    <NavLink  to="/dashboard/rendez-vous"
                                              className={({ isActive }) =>
                                                  isActive ? "nav-link active" : "nav-link"
                                              }>
                                        <i className="fe fe-layout"></i> <span>Rendez-vous</span>
                                    </NavLink>
                                </li> */}
                                <li
                                    className={pathname?.includes("clients") ? "active" : ""}
                                >
                                    <Link to="/dashboard/clients">
                                        <i className="fe fe-user"></i> <span>Clients</span>
                                    </Link>
                                </li> 
                                <li
                                    className={pathname?.includes("groupes") ? "active" : ""}
                                >
                                    <Link to="/dashboard/groupes">
                                        <i className=" fe  fe-users "></i>
                                        <span>Groupes</span>
                                    </Link>
                                </li>

                              
                                {/* <li
                                    className={pathname?.includes("groupespersonnes") ? "active" : ""}
                                >
                                    <Link to="/dashboard/groupespersonnes">
                                        <i className="fe fe-user"></i> <span>Patients</span>
                                    </Link>
                                </li> */}
                                <li
                                    className={
                                        pathname?.includes("personnel") ? "active" : "" }
                                >
                                    <Link to="/dashboard/personnel">
                                        <i className="fe fe-user-plus"></i>
                                        <span>Personnels</span>
                                    </Link>
                                </li>
                                <li className={pathname?.includes("message") ? "active" : ""}>
                                    <Link to="/dashboard/message">
                                        <i className="fe fe-mail "></i> <span>Messages</span>
                                    </Link>
                                </li>
                                <li className={pathname?.includes("Membre") ? "active" : ""}>
                                    <Link to="/dashboard/Membre">
                                        <i className="fe fe-users "></i> <span>Membres</span>
                                    </Link>
                                </li>
                                <li className={pathname?.includes("Envoismail") ? "active" : ""}>
                                    <Link to="/dashboard/Envoismail">
                                        <i className="fe fe-mail  "></i> <span>Emails</span>
                                    </Link>
                                </li>
                               
                              
                            </ul>
                        </div>
                    </div>
                </Scrollbars>

            </div>
            {/* <!-- /Sidebar --> */}
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header  onMenuClick={() => toggleMobileMenu()} />
                <Routes>

                    <Route path="/" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><Home /></ProtectedRoute> } />
                    <Route path="/personnel" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><Employe /></ProtectedRoute> } />
                    <Route path="/groupes" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><Groupes /></ProtectedRoute>} />
                    <Route path="/groupespersonnes" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><GroupesPerso /></ProtectedRoute>} />
                    <Route path="/messageprog" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><MessageProgr /></ProtectedRoute>} />
                    <Route path="/clients" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><Clients /></ProtectedRoute>} />
                    <Route path="/form-vertical" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><VerticalForm /></ProtectedRoute>} />
                    <Route path="/profile" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><Profile /></ProtectedRoute>} />
                    <Route path="/Message-individuel" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><MessageindForm /></ProtectedRoute>} />
                    <Route path="/parametre" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><Settings /></ProtectedRoute>} />
                    <Route path="/instantane" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><MessageSimple /></ProtectedRoute>} />
                    <Route path="/message" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><MessageList /></ProtectedRoute>} />
                    <Route path="/Membre" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><Membre /></ProtectedRoute>} />
                    <Route path="/Envoismail" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><Email /></ProtectedRoute>} />
                    <Route path="/Envoismailgroupe" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><Emailgroupe /></ProtectedRoute>} />
                    <Route path="/EnvoismailInd" element={<ProtectedRoute isAllowed={user.isAuth } redirectPath= "/"><EmailInd /></ProtectedRoute>} />




                </Routes>
</div>

        </>
    );
};

export default Dashboard;
