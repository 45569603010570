/* eslint-disable react/prop-types */
import {Link} from "react-router-dom";
// import FeatherIcon from "feather-icons-react";
// import { avatar01, avatar02, avatar03, avatar05, avatar06, logo, logoSmall } from '../imagepath'
// import { Appcontext } from "../../../approuter";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom/dist";
import {avatar01, doctor_thumb_01, logo, logoSmall, patient1,} from "../imagepath";
import {deleteUser, getUser} from "../../services/storage";
import {AppContext, initialUser} from "../../services/context";

const Header = () => {
  // let pathname = props.location.pathname;
  // const { isAuth, setIsAuth } = useContext(Appcontext);
  // const [task, settask] = useState(true);
  // const [task1, settask1] = useState(true);
  // const [dropdown, setdropdown] = useState(false);
  // const [dropdown1, setdropdown1] = useState(false);

  // const handletheme = () => {
  //   document.body.classList.toggle("darkmode");
  //   settask(!task);
  //   settask1(!task1);
  // };
  // const handledropdown = () => {
  //   setdropdown(!dropdown);
  //   setdropdown1(false);
  // };
  // const handledropdown1 = () => {
  //   setdropdown1(!dropdown1);
  //   setdropdown(false);
  // };


//  if (props.location.pathname.split("/")[1] === "admin") {


  // }

  const exclusionArray = ["/admin/login"];
  /*if (exclusionArray.indexOf(props.location.pathname) >= 0) {
     return "";
   }*/
  const authCtx = useContext(AppContext);
  const {user, onUserChange} = authCtx;
  const [userLocal, setUserLocal] = useState("")
  let navigate = useNavigate();

  useEffect(() => {
    //return navigate("/dashboard/")
    setUserLocal(getUser())
    isAuth();
  }, [user]);
  const isAuth = () => {
    if (user.isAuth == false || user.token == null || user.token == "") {
      console.log(`connexion échoué, isAuth`);
      console.log(user);

      //return navigate("/");
    } else {
      console.log("isAuth true");
    }
  };

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };
  const handlesidebarmobilemenu = () => {
    document.body.classList.toggle("slide-nav");
  };

  const deconnect = () => {
    deleteUser();
    onUserChange(initialUser);
  };
//
  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div className="header-left">
        <Link to="/" className="logo">
           <img src={logo} alt="Logo" width="800" height="1000" />
         </Link>

          <Link to="/" className="logo logo-small">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <Link to="#" id="toggle_btn" onClick={handlesidebar}>
          <i className="fe fe-text-align-left"></i>
        </Link>
        {/* /Logo */}


        {/* Mobile Menu Toggle */}
        <Link
          to="#"
          className="mobile_btn"
          id="mobile_btn"
          onClick={() => handlesidebarmobilemenu()}
        >
          <i className="fa fa-bars"/>
        </Link>
        {/* /Mobile Menu Toggle */}
        {/* Header Right Menu */}
        <ul className="nav user-menu">
          {/* Notifications
          <li className="nav-item dropdown noti-dropdown">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <i className="fe fe-bell"/>{" "}
              <span className="badge rounded-pill">3</span>
            </Link>
            <div className="dropdown-menu notifications">
              <div className="topnav-dropdown-header">
                <span className="notification-title">Notifications</span>
                <Link to="#" className="clear-noti">
                  {" "}
                  Effacer tout{" "}
                </Link>
              </div>
              <div className="noti-content">
                <ul className="notification-list">
                  <li className="notification-message">
                    <Link to="#">
                      <div className="media d-flex">
                        <span className="avatar avatar-sm flex-shrink-0">
                          <img
                            className="avatar-img rounded-circle"
                            alt="User Image"
                            src={doctor_thumb_01}
                          />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            <span className="noti-title"> Ruby Perrin</span>{" "}
                            Schedule{" "}
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">
                              4 mins 
                            </span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="notification-message">
                    <Link to="#">
                      <div className="media d-flex">
                        <span className="avatar avatar-sm flex-shrink-0">

                        <img
                          className="avatar-img rounded-circle"
                          alt="User Image"
                          src={patient1}
                        />



                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            <span className="noti-title"></span>{" "}
                            <span className="noti-title"> Ruby Perrin</span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">
                              6 mins 
                            </span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>

                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="#">Voir toutes les Notifications</Link>
              </div>
            </div>
          </li>*/}
          {/* /Notifications */}
          {/* User Menu */}
          <li className="nav-item dropdown has-arrow">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <span className="user-img">
                <img
                  className="rounded-circle"
                  src={avatar01}
                  width={31}
                  alt={user.name}
                />
              </span>
            </Link>
            <div className="dropdown-menu">
              <div className="user-header">
                <div className="avatar avatar-sm">
                  <img
                     src={avatar01}
                    alt="User Image"
                    className="avatar-img rounded-circle"
                  />
                </div>
                <div className="user-text">
                  <h6>{user.name}</h6>
                  <p className="text-muted mb-0">{user.organisation}</p>
                </div>
              </div>
              <Link className="dropdown-item" to="/dashboard/profile">
                Mon Profile
              </Link>
              <Link className="dropdown-item" to="/settings">
                Paramètre
              </Link>
              <Link className="dropdown-item" to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      deconnect();
                    }}
              >
                Se déconnecter
              </Link>
            </div>
          </li>
          {/* /User Menu */}
        </ul>
        {/* /Header Right Menu */}
      </div>

      {/* /Header */}
    </>
  );
};

export default Header;
