import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import SidebarNav from "../components/sidebar";
import Header from "../components/header";
import hp from "../assets/imgs/home_profile.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PatientsListDesboard from "../components/dashboard/PatientsList";
import AppointmentList from "../components/dashboard/AppointmentList";
import LineChart from "../components/dashboard/LineChart";
import StatusCharts from "../components/dashboard/StatusCharts";
import { useNavigate } from "react-router-dom/dist";
import { deleteUser, getUser } from "../services/storage";
import { AppContext, initialUser } from "../services/context";
import ProtectedRoute from "../components/componentsmedicsoft/ProtectedRoute";
import Employe from "./Employe";
import Settings from "./Settings";
import Meet from "./Meet";
import requestUser, { URLLVM } from "../services/requestUser";
import { apiMedical, apiUser } from "../services/api";
import requestDoctor from "../services/requestDoctor";
import axios from "axios";
import DoctorListDesboard from "../components/dashboard/DoctorList";

const Home = () => {
  const authCtx = useContext(AppContext);
  const { user, onUserChange } = authCtx;
  const [userImg, setUserImg] = useState(hp);
  const [value, onChange] = useState(new Date());
  const [rdv, setRdv] = useState([]);
  const [stat, setStat] = useState({});
  const [groupCount, setGroupCount] = useState(0);
  const [clientsCount,  setClientsCount] = useState(0);
  const [submittedData, setSubmittedData] = useState([]);
  const [usersCount,  setUsersCount] = useState(0);
  const [messagesCount,  setMessagesCount] = useState(0);
  const [userLocal, setUserLocal] = useState("")



  const header = {
    headers: { Authorization: `${user.token}` },
  };
  useEffect(() => {
    setUserImg(user.profile);
    getUserInfos()
    getEvent();
    getStatistique();
  }, []);

  useEffect(() => {
    //return navigate("/dashboard/")
    setUserLocal(getUser())
    isAuth();
  }, [user]);
  const isAuth = () => {
    if (user.isAuth == false || user.token == null || user.token == "") {
      console.log(`connexion échoué, isAuth`);
      console.log(user);

      //return navigate("/");
    } else {
      console.log("isAuth true");
    }
  };


  
    useEffect(() => {
      const fetchGroupCount = async () => {
        try {
          const response = await axios.get(
            "https://www.staging.messagelinkpro.com/messagelinkpro/groupe/liste"
          );
          setGroupCount(response.data.length); // Assumes the response is an array of groups
        } catch (error) {
          console.error("Error fetching group count:", error);
        }
      };
  
      fetchGroupCount();
    }, []);

    useEffect(() => {

      const fetchClientsCount = async () => {
        try {
          const response = await axios.get(
            "https://www.staging.messagelinkpro.com/messagelinkpro/client/liste"
          );
          setSubmittedData(response.data.length);
          setClientsCount(response.data.length);
        } catch (error) {
          console.error("Erreur lors de la récupération et compte des clients:", error);
        }
      };
      fetchClientsCount();
    }, []);
  
   
    useEffect(() => {
      const fetchUsersCount = async () => {
        try {
          const response = await axios.get("https://www.staging.messagelinkpro.com/messagelinkpro/profile/collect");
          setUsersCount(response.data.length);
        } catch (error) {
          console.error("Error fetching users:", error);
          
        }
      };
  
      fetchUsersCount();
    }, []);
  
    useEffect(() => {
      const fetchMessagesCount = async () => {
        try {
          const response = await axios.get("https://www.staging.messagelinkpro.com/messagelinkpro/external-api/message/liste");
          setSubmittedData(response.data.length);
          setMessagesCount(response.data.length);

        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      };
      fetchMessagesCount();
    }, []);

  const getUserInfos = () => {
    requestUser
        .get(apiUser.get + "/" + user.organisationRef, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
        .then((res) => {
          ///console.log(res.data.photo);
          user.profile = res.data.photoFileName;
          setUserImg(res.data.photoFileName);
          onUserChange(user);
          //console.log(res.data.employeeResponseList);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const getEvent = () => {
    requestDoctor
        .get(
            apiMedical.appointmentConfirmWait + "/" + user.organisationRef,
            header
        )
        .then((res) => {
          console.log(res.data);
          //setEmployeList(res.data.employees);
          console.log(res.data);
          setRdv(res.data)
        })
        .catch((error) => {});
  };
  const getStatistique = () => {
    requestDoctor
        .get(
            apiMedical.statistique + "/" + user.organisationRef,
            header
        )
        .then((res) => {
          console.log(res.data);
          //setEmployeList(res.data.employees);
          setStat(res.data);
        })
        .catch((error) => {});
  };

   // Calcul des pourcentages
   const maxClients = 100;
   const maxGroups = 100; 
   const maxUsers = 100; 
   const maxMessages = 100; 
 
   const clientsProgress = (clientsCount / maxClients) * 100;
   const groupProgress = (groupCount / maxGroups) * 100;
   const usersProgress = (usersCount / maxUsers) * 100;
   const messagesProgress = (messagesCount / maxMessages) * 100;
 
   return (
    <>
     <div className="page-wrapper">
       <div className="content container-fluid pb-0">
         <div className="page-header">
           <div className="row">
             <div className="col-sm-12">
               <h3 className="page-title">Bienvenue : {user.name}</h3>
               <ul className="breadcrumb">
                 <li className="breadcrumb-item active">Tableau de bord</li>
               </ul>
             </div>
           </div>
         </div>
         <div className="row">
           {/* Clients */}
           <div className="col-xl-3 col-sm-6 col-12">
             <div className="card">
               <div className="card-body">
                 <div className="dash-widget-header">
                   <span className="dash-widget-icon text-primary border-primary">
                     <i className="fe fe-users" />
                   </span>
                   <div className="dash-count">
                     <h3>{clientsCount}</h3>
                   </div>
                 </div>
                 <div className="dash-widget-info">
                   <h6 className="text-muted">Clients</h6>
                   <div className="progress progress-sm">
                     <div
                       className="progress-bar bg-primary"
                       style={{ width: `${clientsProgress}%` }}
                     />
                   </div>
                 </div>
               </div>
             </div>
           </div>
           {/* Groupes */}
           <div className="col-xl-3 col-sm-6 col-12">
             <div className="card">
               <div className="card-body">
                 <div className="dash-widget-header">
                   <span className="dash-widget-icon text-success">
                     <i className="fe fe-credit-card" />
                   </span>
                   <div className="dash-count">
                     <h3>{groupCount}</h3>
                   </div>
                 </div>
                 <div className="dash-widget-info">
                   <h6 className="text-muted">Groupes</h6>
                   <div className="progress progress-sm">
                     <div
                       className="progress-bar bg-success"
                       style={{ width: `${groupProgress}%` }}
                     />
                   </div>
                 </div>
               </div>
             </div>
           </div>
           {/* Utilisateurs */}
           <div className="col-xl-3 col-sm-6 col-12">
             <div className="card">
               <div className="card-body">
                 <div className="dash-widget-header">
                   <span className="dash-widget-icon text-danger border-danger">
                     <i className="fe fe-user" />
                   </span>
                   <div className="dash-count">
                     <h3>{usersCount}</h3>
                   </div>
                 </div>
                 <div className="dash-widget-info">
                   <h6 className="text-muted">Membres</h6>
                   <div className="progress progress-sm">
                     <div
                       className="progress-bar bg-danger"
                       style={{ width: `${usersProgress}%` }}
                     />
                   </div>
                 </div>
               </div>
             </div>
           </div>
           {/* Messages */}
           <div className="col-xl-3 col-sm-6 col-12">
             <div className="card">
               <div className="card-body">
                 <div className="dash-widget-header">
                   <span className="dash-widget-icon text-warning border-warning">
                     <i className="fe fe-folder" />
                   </span>
                   <div className="dash-count">
                     <h3>{messagesCount}</h3>
                   </div>
                 </div>
                 <div className="dash-widget-info">
                   <h6 className="text-muted">Messages</h6>
                   <div className="progress progress-sm">
                     <div
                       className="progress-bar bg-warning"
                       style={{ width: `${messagesProgress}%` }}
                     />
                   </div>
                      </div>
                    </div>
                  </div>
                </div>

                 {/* Mails */}
           <div className="col-xl-3 col-sm-6 col-12">
             <div className="card">
               <div className="card-body">
                 <div className="dash-widget-header">
                   <span className="dash-widget-icon text-warning border-warning">
                     <i className="fe fe-folder" />
                   </span>
                   <div className="dash-count">
                     <h3>{messagesCount}</h3>
                   </div>
                 </div>
                 <div className="dash-widget-info">
                   <h6 className="text-muted">Emails</h6>
                   <div className="progress progress-sm">
                     <div
                       className="progress-bar bg-warning"
                       style={{ width: `${messagesProgress}%` }}
                     />
                   </div>
                      </div>
                    </div>
                  </div>
                </div>

                 {/* Personnel */}
           <div className="col-xl-3 col-sm-6 col-12">
             <div className="card">
               <div className="card-body">
                 <div className="dash-widget-header">
                   <span className="dash-widget-icon text-warning border-warning">
                     <i className="fe fe-folder" />
                   </span>
                   <div className="dash-count">
                     <h3>{messagesCount}</h3>
                   </div>
                 </div>
                 <div className="dash-widget-info">
                   <h6 className="text-muted">Personnels</h6>
                   <div className="progress progress-sm">
                     <div
                       className="progress-bar bg-warning"
                       style={{ width: `${messagesProgress}%` }}
                     />
                   </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                {/* Sales Chart */}
                <div className="card card-chart">
                  <div className="card-header">
                    <h4 className="card-title">Message</h4>
                  </div>
                  <div className="card-body">
                    {/* <div id="morrisArea" /> */}
                    <LineChart />
                  </div>
                </div>
                {/* /Sales Chart */}
              </div>
              <div className="col-md-12 col-lg-6">
                {/* Invoice Chart */}
                <div className="card card-chart">
                  <div className="card-header">
                    <h4 className="card-title">Status</h4>
                  </div>
                  <div className="card-body">
                    <div id="morrisLine" />
                    {/* <LineChart /> */}
                    {/* <StatusChart /> */}
                    <StatusCharts />
                  </div>
                </div>
                {/* /Invoice Chart */}
              </div>
            </div>

            {/* <div className="row">
              <DoctorListDesboard/>
              <PatientsListDesboard />
            </div> */}
            {/* Today’s  Appointment */}
            <div className="row">
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}

    </>
  );
};

export default Home;
