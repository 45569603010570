import React, {useContext, useState, useEffect } from "react";
import { Table, Modal, Button, Input } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import {AppContext} from "../../services/context";


const MessageSimple = () => {
  const [submittedData, setSubmittedData] = useState([]);
  const authCtx = useContext(AppContext);
  const {user, onUserChange} = authCtx;

  const [viewGroup, setViewGroup] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [annulerGroup, setAnnulerGroup] = useState(null);
  const [annulerModalVisible, setAnnulerModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState(""); // Nouvelle variable pour stocker le texte de recherche

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get("http://localhost:8083/messagelinkpro/external-api/messageliste",
          {
            headers: {
              Authorization: `Bearer ${user.token}`
            },
          }
        );
        setSubmittedData(response.data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    fetchMessages();
  }, []);

  const handleDelete = async (idMessage) => {
    try {
      await axios.delete(`http://localhost:8083/messagelinkpro/message/delete/${idMessage}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          },
        }
      );
      setSubmittedData(submittedData.filter((message) => message.idMessage !== idMessage));
      setDeleteModalVisible(false);
      setDeleteGroup(null);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Erreur lors de la suppression du message. Veuillez réessayer.");
    }
  };

  const handleCancel = async (sid) => {
    try {
      await axios.delete(`http://localhost:8083/messagelinkpro/external-api/message/annuler/${sid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          },
        }
      );
      setSubmittedData(submittedData.filter((message) => message.sid !== sid));
      setAnnulerModalVisible(false);
      setAnnulerGroup(null);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Erreur lors de l'annulation du message. Veuillez réessayer.");
    }
  };

  const handleView = (message) => {
    setViewGroup(message);
    setViewModalVisible(true);
  };

  const handleDeleteConfirm = (message) => {
    setDeleteGroup(message);
    setDeleteModalVisible(true);
  };

  const handleAnnuleConfirm = (message) => {
    setAnnulerGroup(message);
    setAnnulerModalVisible(true);
  };

  const getStatusStyle = (status) => {
    const styles = {
      accepted: { backgroundColor: "#008000", color: "white" },
      delivered: { backgroundColor: "#008000", color: "white" },
      failed: { backgroundColor: "#FFFF00", color: "white" },
      canceled: { backgroundColor: "#FF0000", color: "black" },
      undelivered: { backgroundColor: "#FF0000", color: "white" },
    };
    return styles[status] || { backgroundColor: "#ffffff", color: "black" };
  };

  // Filtrage des messages en fonction de searchText
  // const filteredData = submittedData.filter((message) => {
  //   const phoneNumber = message.phoneNumber?.toLowerCase() || "";
  //   const messageText = message.message?.toLowerCase() || "";
  //   const status = message.status?.toLowerCase() || "";

  //   return (
  //     phoneNumber.includes(searchText.toLowerCase()) ||
  //     messageText.includes(searchText.toLowerCase()) ||
  //     status.includes(searchText.toLowerCase())
  //   );
  // });
  // const sortedFilteredData = [...filteredData].sort((a, b) => new Date(b.created) - new Date(a.created));

  const filteredData = submittedData.filter((message) => {
    if (!message) return false;
  
    const phoneNumber = String(message.phoneNumber || "").toLowerCase();
    const messageText = (message.message || "").toLowerCase();
    const status = (message.status || "").toLowerCase();
  
    return (
      phoneNumber.includes(searchText.toLowerCase()) ||
      messageText.includes(searchText.toLowerCase()) ||
      status.includes(searchText.toLowerCase())
    );
  });
  
  const sortedFilteredData = [...filteredData].sort(
    (a, b) => new Date(b.created) - new Date(a.created)
  );
  

  return (
    <>
    
    <div className="col-sm-7 col-aut0">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                  <div className="d-inline-block">

                  <div className="top-nav-search">
                    <form>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Rechercher par..."
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <button className="btn" type="submit">
                            <i className="fa fa-search"/>
                        </button>
                    </form>
                </div>
          </div>  </li>
                </ul>
              </div> 
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              {/* <div className="card-header">
                <h4 className="card-title">Liste des messages instantanés</h4>
              </div>
           */}

              <div className="table-responsive">
                <Table
                  columns={[
                    {
                      title: "Ordre",
                      render: (text, record, index) => index + 1,
                    },
                    {
                      title: "N° Téléphone",
                      dataIndex: "phoneNumber",
                    },
                    {
                      title: "Message",
                      dataIndex: "message",
                    },
                    {
                      title: "Status",
                      dataIndex: "status",
                      render: (status) => {
                        const { backgroundColor, color } = getStatusStyle(status);
                        return (
                          <span style={{ backgroundColor, color, padding: '5px 10px', borderRadius: '4px' }}>
                            {status}
                          </span>
                        );
                      },
                    },
                    {
                      title: "Date d'envoi",
                      dataIndex: 'created',
                      render: (text) => {
                        if (!text) return "";
                        const date = new Date(text);
                        return `${date.toLocaleDateString('fr-FR')} ${date.toLocaleTimeString('fr-FR')}`;
                      },
                    },
                    {
                      title: "Action",
                      className: "text-end",
                      render: (text, record) => (
                        <div className="text-end">
                          <a href="#" className="me-1 btn btn-sm bg-primary-light" onClick={() => handleView(record)}>
                            <i className="fe fe-eye"></i> Voir
                          </a>
                          {record.status === "scheduled" && (
                            <a href="#" className="me-1 btn btn-sm bg-warning-light" onClick={() => handleAnnuleConfirm(record)}>
                              <i className="fe fe-eye"></i> Annuler
                            </a>
                          )}
                          {(record.status === "accepted" || record.status === "delivered" || record.status === "failed" || record.status === "canceled" || record.status === "undelivered") && (
                            <a
                              href="#"
                              className="me-1 btn btn-sm bg-danger-light"
                              onClick={() => handleDeleteConfirm(record)}
                              style={{ backgroundColor: getStatusStyle(record.status).backgroundColor }}
                            >
                              <i className="fe fe-trash"></i> Supprimer
                            </a>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  dataSource={sortedFilteredData}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal title="Voir le Message" visible={viewModalVisible} onCancel={() => setViewModalVisible(false)} footer={null}>
        {viewGroup && (
          <div>
            <p><strong>N° Téléphone: </strong>{viewGroup.phoneNumber}</p>
            <p><strong>Message: </strong>{viewGroup.message}</p>
          </div>
        )}
      </Modal>

      <Modal
        title="Confirmation de suppression"
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setDeleteModalVisible(false)}>Annuler</Button>,
          <Button key="submit" type="primary" onClick={() => handleDelete(deleteGroup.id)}>Supprimer</Button>,
        ]}
      >
        <p>Êtes-vous sûr de vouloir supprimer ce message ?</p>
      </Modal>

      <Modal
        title="Confirmation de l'annulation"
        visible={annulerModalVisible}
        onCancel={() => setAnnulerModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setAnnulerModalVisible(false)}>Abandonner</Button>,
          <Button key="submit" type="primary" onClick={() => handleCancel(annulerGroup.sid)}>Annuler</Button>,
        ]}
      >
        <p>Êtes-vous sûr de vouloir Annuler ce message ?</p>
      </Modal>
    </>
  );
};

export default MessageSimple;
