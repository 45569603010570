import React, { useState } from 'react'; 
import axios from 'axios';
import { notification } from 'antd';

const EmailForm = () => {
    const [to, setTo] = useState(''); // Adresses e-mails des destinataires
    const [subject, setSubject] = useState(''); // Objet du mail
    const [text, setText] = useState(''); // Corps du mail
    const [cc, setCc] = useState(''); // Copie conforme
    const [attachments, setAttachments] = useState([]); // Fichiers joints
    const [error, setError] = useState('');

    // Fonction de notification
    const openNotificationWithIcon = (type, description) => {
        notification[type]({
            message: type === 'success' ? 'Succès' : 'Erreur',
            description,
        });
    };

    // Gestion de la sélection de fichiers
    const handleFileChange = (e) => {
        setAttachments(e.target.files);
    };

    // Fonction d'envoi de l'email
    const envoyerSubmit = async (e) => {
        e.preventDefault();

        // Valider que le champ 'to' n'est pas vide
        if (!to) {
            openNotificationWithIcon('error', 'Le champ "À" est requis.');
            return;
        }

        const formData = new FormData();
        formData.append('to', to);
        formData.append('subject', subject);
        formData.append('text', text);
        formData.append('cc', cc);

        // Ajouter les fichiers sélectionnés dans formData
        for (let i = 0; i < attachments.length; i++) {
            formData.append('attachments', attachments[i]);
        }

        try {
            const response = await axios.post(
                'http://www.staging.messagelinkpro.com/messagelinkpro/external-api/email/envoye',
                formData,
                // {
                //     headers: {
                //         'Content-Type': 'multipart/form-data',
                //     },
                // }
            );
            console.log('Email envoyé avec succès :', response.data);
            openNotificationWithIcon('success', 'L\'e-mail a été envoyé avec succès.');
        } catch (error) {
            console.error('Erreur lors de l\'envoi de l\'e-mail :', error);
            openNotificationWithIcon('error', 'Une erreur s\'est produite lors de l\'envoi de l\'e-mail.');
        }
    };

    return (
        <>
            <form onSubmit={envoyerSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="card-title">Envoyer un E-mail</h4>

                        <div className="form-group">
                            <label>À:</label>
                            <input
                                type='email'
                                className='form-control'
                                value={to}
                                onChange={(e) => setTo(e.target.value)}
                                placeholder="Entrer l'adresse e-mail du destinataire"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Objet:</label>
                            <input
                                type='text'
                                className='form-control'
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Message:</label>
                            <textarea
                                rows="4"
                                className="form-control"
                                placeholder="Entrer votre message"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>CC (copie conforme):</label>
                            <input
                                type='email'
                                className='form-control'
                                value={cc}
                                onChange={(e) => setCc(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label>Pièces jointes :</label>
                            <input
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                                multiple
                            />
                        </div>
                    </div>
                </div>
                
                <div className="text-end">
                    <button type="submit" className="btn btn-primary">
                        Envoyer l'e-mail
                    </button>
                </div>
            </form>
        </>
    );
}

export default EmailForm;
