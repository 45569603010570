import React, { useState } from 'react';
import axios from 'axios';
import { notification } from 'antd';

const MessageindisnForm = () => {
    const [showRepeat, setShowRepeat] = useState(false);
    const toggleRepeat = () => {
        setShowRepeat(!showRepeat);
    };
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dateTime, setDateTime] = useState('');
    // const [sendTime, setSendTime] = useState('');
    const [message, setMessage] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [messageError, setMessageError] = useState('');
    const openNotificationWithIcon = (type, description) => {
        notification[type]({
            message: type === 'success' ? 'Succès' : 'Erreur',
            description,
        });
    };

    const validatePhoneNumber = (number) => {
        const formattedNumber = number.startsWith('+226') ? number : `+226${number}`;
        const regex = /^\+226\d{8}$/; // Format de numéro de téléphone valide pour le Burkina Faso

        if (!regex.test(formattedNumber)) {
            return { isValid: false, formattedNumber };
        }

        return { isValid: true, formattedNumber };
    };

    const envoyerSubmit = async (e) => {
        e.preventDefault();
        const phoneNumberArray = phoneNumber.split(',').map(number => number.trim());

        try {
            const response = await axios.post('http://www.api.messagelinkpro.com/messagelinkpro/message/send/simple', {
                phoneNumber: phoneNumberArray,
                message,
            });
            console.log('Message envoyé :', { phoneNumber, message });
            openNotificationWithIcon('success', 'Le message instantané a été envoyé avec succès.');
        } catch (error) {
            console.error('Erreur lors de l\'envoi du message :', error);
            openNotificationWithIcon('error', 'Une erreur s\'est produite lors de l\'envoi du message instantané.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let hasError = false;

        // Réinitialiser les messages d'erreur
        setPhoneNumberError('');
        setMessageError('');

        // Validation des champs
        if (!phoneNumber) {
            setPhoneNumberError('Veuillez entrer un numéro de téléphone.');
            hasError = true;
        } else {
            const { isValid, formattedNumber } = validatePhoneNumber(phoneNumber);
            if (!isValid) {
                setPhoneNumberError('Veuillez entrer un numéro de téléphone valide.');
                hasError = true;
            } else {
                setPhoneNumber(formattedNumber);
            }
        }

        if (!message) {
            setMessageError('Veuillez entrer un message.');
            hasError = true;
        }

        // Si des erreurs existent, ne pas soumettre le formulaire
        if (hasError) return;


        // Envoyer les données après validation

    };

    return (
        <>

            <form onSubmit={envoyerSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="card-title">Message</h4>
                        <div className="form-group">
                            <label>Numero:</label>
                            <input
                                type='text'
                                className='form-control'
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)} required
                            />
                            {phoneNumberError && (
                                <small className="text-danger">{phoneNumberError}</small>
                            )}
                        </div>
                        <div className="form-group">
                            <label>Message:</label>
                            <textarea
                                rows="4"
                                className="form-control h-75"
                                placeholder="Entrer un message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)} required
                            />
                            {messageError && (
                                <small className="text-danger">{messageError}</small>
                            )}
                        </div>
                    </div>
                </div>
                <div className="text-end">
                    <button type="submit" className="btn btn-primary">
                        Envoyer
                    </button>
                </div>
            </form>


        </>
    );
}
export default MessageindisnForm;
