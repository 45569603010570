import React, {useContext, useState, useEffect } from "react";
import { Table, Modal, Button, Input, notification } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link } from "react-router-dom";
import axios from "axios";
import {AppContext} from "../../services/context";

const Doctors = () => {
  const authCtx = useContext(AppContext);
  const {user, onUserChange} = authCtx;
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editGroup, setEditGroup] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewGroup, setViewGroup] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [submittedData, setSubmittedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [ nomError,  setNomError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(
          "http://localhost:8083/messagelinkpro/external-api/groupe/liste",
          {
            headers: {
              Authorization: `Bearer ${user.token}`
            },
          }
        );
        setSubmittedData(response.data); 
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchGroups();
  }, []);

  
  const openNotification = (message, description) => {
    notification.success({
      message,
      description,
    });
  };

 

    const handleSubmit = async (e) => {
      e.preventDefault();
      let hasError = false;
  
      // Réinitialiser les messages d'erreur
      setErrorMessage('');
      setNomError('');
      setDescriptionError('');
  
      // Validation des champs
      if (!nom) {
        setNomError('Veuillez entrer un nom du groupe.');
        hasError = true;
      }
      if (!description) {
        setDescriptionError('Veuillez entrer une description.');
        hasError = true;
      }
     
      // Afficher la notification d'erreur si des champs ne sont pas remplis
      if (hasError) {
        // openNotificationWithIcon('error', "Veuillez remplir tous les champs.");
        return;
      }
    try {
      const response = await axios.post(
        "https://www.staging.messagelinkpro.com/messagelinkpro/external-api/groupe/create",

        { nom, description },
        {
          headers: {
            Authorization: `Bearer ${user.token}`, // Replace with your token
            "Content-Type": "application/json", // Adjust content type if needed
          },
        }
      );
      const newData = { id: response.data.id, nom, description,      created: new Date().toISOString() 
      };
      setSubmittedData([newData, ...submittedData]);
      setNom("");
      setDescription("");
      setIsModalVisible(false);
      openNotification("Succès", "Groupe ajouté avec succès");
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Erreur lors de l'ajout du groupe. Veuillez réessayer.");
    }
  };



  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!editGroup || !editGroup.nom || !editGroup.description) {
      setErrorMessage("Veuillez remplir tous les champs .");
      return;
    }
    try {
      const response = await axios.put(
        `https://www.staging.messagelinkpro.com/messagelinkpro/external-api/groupe/modifier/${editGroup.idGroupe}`,
        {
          nom: editGroup.nom,
          description: editGroup.description,
        }
      );
      const updatedData = submittedData.map((group) =>
        group.idGroupe === editGroup.idGroupe? { ...group, nom: editGroup.nom, description: editGroup.description } : group
      );
      setSubmittedData(updatedData);
      setEditModalVisible(false);
      setEditGroup(null);
      openNotification("Succès", "Groupe modifié avec succès");
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Erreur lors de la modification du groupe. Veuillez réessayer.");
    }
  };

  const handleDelete = async (idGroupe) => {
    try {
      await axios.delete(`https://www.staging.messagelinkpro.com/messagelinkpro/external-api/groupe/delete/${idGroupe}`);
      setSubmittedData(submittedData.filter((group) => group.idGroupe !== idGroupe));
      setDeleteModalVisible(false);
      setDeleteGroup(null);
      openNotification("Succès", "Groupe supprimé avec succès");
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Erreur lors de la suppression du groupe. Veuillez réessayer.");
    }
  };

  const handleView = (group) => {
    setViewGroup(group);
    setViewModalVisible(true);
  };

  const handleEdit = (group) => {
    setEditGroup(group);
    setEditModalVisible(true);
  };

  const handleDeleteConfirm = (group) => {
    setDeleteGroup(group);
    setDeleteModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNom("");
    setDescription("");
    setErrorMessage("");
  };

  const filteredData = submittedData.filter(
    (group) =>
      group.nom.toLowerCase().includes(searchText.toLowerCase()) ||
      group.description.toLowerCase().includes(searchText.toLowerCase())
  );
  const sortedFilteredData = [...filteredData].sort((a, b) => new Date(b.created) - new Date(a.created));

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7 col-auto">
                <h3 className="page-title">Groupes</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active">Groupes</li>
                </ul>
                <div className="top-nav-search">
                    <form>
                    
                        <input
                            className="form-control"
                            placeholder="Rechercher "
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            />
                        <button className="btn" type="submit">
                            <i className="fa fa-search"/>
                        </button>
                    </form>
                </div>
              </div>
              <div className="col-sm-5 col">
                <button
                  className="btn btn-primary float-end mt-2"
                  onClick={() => setIsModalVisible(true)}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      columns={[
                        {
                          title: "Ordre",
                          render: (text, record, index) => index + 1,
                        },
                        {
                          title: "Nom",
                          dataIndex: "nom",
                          // render: (text, record) => (
                          //   <Link to="/admin/profile">{text}</Link>
                          // ),
                        },
                        {
                          title: "Description",
                          dataIndex: "description",
                        },
                        {
                          title: "Date d'ajout",
                          dataIndex: "created",
                          key: "created",
                          render: (text) => {
                            if (!text) {
                              return "";
                            }

                            const date = new Date(text);
                            const formattedDate = date.toLocaleDateString("fr-FR");
                            const formattedTime = date.toLocaleTimeString("fr-FR", {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            });

                            return `${formattedDate} ${formattedTime}`;
                          },
                        },
                        {
                          title: "Action",
                          className: "text-center",
                          render: (text, record) => (
                            <div className="text-center">
                              <a
                                href="#"
                                className="me-1 btn btn-sm bg-primary-light"
                                onClick={() => handleView(record)}
                              >
                                <i className="fe fe-eye"></i> Voir
                              </a>
                              <a
                                href="#"
                                className="me-1 btn btn-sm bg-success-light"
                                onClick={() => handleEdit(record)}
                              >
                                <i className="fe fe-pencil"></i> Editer
                              </a>
                              <a
                                href="#"
                                className="me-1 btn btn-sm bg-danger-light"
                                onClick={() => handleDeleteConfirm(record)}
                              >
                                <i className="fe fe-trash"></i> Supprimer
                              </a>
                            </div>
                          ),
                        },
                      ]}

                      dataSource={sortedFilteredData}  
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<Modal
        title="Ajouter un Groupe"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <form onSubmit={handleSubmit}>
          <div className="row form-row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Nom</label>
                <input
                  type="text"
                  className="form-control"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)} 
                />
                 {setNomError && (
                  <small className="text-danger">{nomError}</small>
       )}
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  value={description}   
                  onChange={(e) => setDescription(e.target.value)} 
                ></textarea>
                {descriptionError && (
                  <small className="text-danger">{descriptionError}</small>
       )}
       
  
              </div>
            </div>
          </div>
          {errorMessage && (
            <div className="text-danger mb-3">{errorMessage}</div>
          )}
          <button type="submit" className="btn btn-primary w-100">
            Enregistrer
          </button>
        </form>
      </Modal>

      <Modal
        title="Modifier un Groupe"
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
      >
        <form onSubmit={handleEditSubmit}>
          <div className="row form-row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Nom</label>
                <input
                  type="text"
                  className="form-control"
                  value={editGroup ? editGroup.nom : ""}
                  onChange={(e) =>
                    setEditGroup({ ...editGroup, nom: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  value={editGroup ? editGroup.description : ""}
                  onChange={(e) =>
                    setEditGroup({ ...editGroup, description: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
          </div>
          {errorMessage && (
            <div className="text-danger mb-3">{errorMessage}</div>
          )}
          <button type="submit" className="btn btn-primary w-100">
            Enregistrer
          </button>
        </form>
      </Modal>

      <Modal
        title="Voir le Groupe"
        visible={viewModalVisible}
        onCancel={() => setViewModalVisible(false)}
        footer={null}
      >
        {viewGroup && (
          <div>
            <p><strong>Nom: </strong>{viewGroup.nom}</p>
            <p><strong>Description: </strong>{viewGroup.description}</p>
            <p><strong>Date d'ajout: </strong>{new Date(viewGroup.created).toLocaleDateString("fr-FR")} {new Date(viewGroup.created).toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })}</p>          </div>
        )}
      </Modal>

      <Modal
        title="Confirmation de suppression"
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setDeleteModalVisible(false)}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleDelete(deleteGroup.id)}>
            Supprimer
          </Button>,
        ]}
      >
        <p>Êtes-vous sûr de vouloir supprimer ce groupe ?</p>
      </Modal>
    </>
  );
};

export default Doctors;
