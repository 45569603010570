import React, {useContext, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { Table, Modal, Button, notification } from "antd";
import PhoneNumberInput from './PhoneNumberInput'; 
import {AppContext} from "../../services/context";



const Clients = () => {
  const [nameClient, setNameClient] = useState("");
  const [telephone, setTelephone] = useState("");
  const [cnib, setCnib] = useState("");
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [nameClientError, setNameClientError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [cnibError, setCnibError] = useState("");
  const [selectedGroupError, setSelectedGroupError] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editGroup, setEditGroup] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewGroup, setViewGroup] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [submittedData, setSubmittedData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const authCtx = useContext(AppContext);
  const {user, onUserChange} = authCtx;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get("http://www.staging.messagelinkpro.com/messagelinkpro/external-api/groupe/liste",
          {
            headers: {
              Authorization: `Bearer ${user.token}`
            },
          }
        );
        setGroups(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des groupes:", error);
      }
    };

    fetchGroups();
  }, []);

  
  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get(
        "http://www.staging.messagelinkpro.com/messagelinkpro/external-api/client/liste",
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          },
        }
      );
      setClients(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des clients:", error);
    }
  };


  useEffect(() => {
    fetchClients();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;
  
    // Réinitialiser les messages d'erreur
    setErrorMessage('');
    setNameClientError('');
    setTelephoneError('');
    setCnibError('');
    setSelectedGroupError('');
  
    // Validation des champs
    if (!nameClient) {
      setNameClientError('Veuillez entrer un nom de client.');
      hasError = true;
    }
  
    // if (!telephone) {
    //   setTelephoneError('Veuillez entrer un numéro de téléphone.');
    //   hasError = true;
    // } else if (!/^\+(226|229|1)\d{8,10}$/.test(telephone)) {
    //   setTelephoneError(
    //     'Numéro de téléphone invalide. Exemple : +22656223851, +22912345678 ou +11234567890'
    //   );
    //   hasError = true;
    // }
    if (!telephone) {
            setTelephoneError('Veuillez entrer un numéro de téléphone.');
            hasError = true;
          }
  
    if (!cnib) {
      setCnibError('Veuillez entrer un CNIB.');
      hasError = true;
    }
  
    // if (!selectedGroup) {
    //   setSelectedGroupError('Veuillez sélectionner un groupe.');
    //   hasError = true;
    // }
  
    // Afficher la notification d'erreur si des champs ne sont pas remplis
    if (hasError) {
      openNotificationWithIcon('error', 'Veuillez remplir tous les champs.');
      return;
    }
  
    const clientData = {
      nameClient,
      telephone,
      cnib,
      group: selectedGroup,
    };
  
    try {
      // setIsSubmitting(true); // Désactiver le bouton
      const response = await axios.post(
        'http://www.staging.messagelinkpro.com/messagelinkpro/external-api/client/create',
        clientData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log('Client ajouté:', response.data);
  
      // Ajouter le nouveau client en haut de la liste
      setClients([response.data, ...clients]);
  
      // Réinitialiser les champs du formulaire
      setNameClient('');
      setTelephone('');
      setCnib('');
      setSelectedGroup('');
  
      setIsModalVisible(false);
      openNotificationWithIcon('success', 'Client ajouté avec succès.');
    } catch (error) {
      console.error('Error:', error);
      const errorMessage =
        error.response?.data?.message || 'Erreur lors de l\'ajout du client.';
      openNotificationWithIcon('error', errorMessage);
      setErrorMessage(errorMessage);
    } 
  };
  

 
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let hasError = false;

//     // Réinitialiser les messages d'erreur
//     setErrorMessage('');
//     setNameClientError('');
//     setTelephoneError('');
//     setCnibError('');
//     setSelectedGroupError('');

//     // Validation des champs
//     if (!nameClient) {
//       setNameClientError('Veuillez entrer un nom de client.');
//       hasError = true;
//     }
//     if (!telephone) {
//       setTelephoneError('Veuillez entrer un numéro de téléphone.');
//       hasError = true;
//     }
//     if (!cnib) {
//       setCnibError('Veuillez entrer un CNIB.');
//       hasError = true;
//     }
//     if (!selectedGroup) {
//       setSelectedGroupError('Veuillez sélectionner un groupe.');
//       hasError = true;
//     }

//     // Afficher la notification d'erreur si des champs ne sont pas remplis
//     if (hasError) {
//       openNotificationWithIcon('error', "Veuillez remplir tous les champs.");
//       return;
//     }
//   const clientData = {
//     nameClient: nameClient,
//     telephone: telephone,
//     cnib: cnib,
//     group: selectedGroup
//   };

//   try {
//     const response = await axios.post(
//       "http://localhost:8083/messagelinkpro/external-api/groupe/create",
//       clientData,
//       {
//         headers: {
//           Authorization: `Bearer ${user.token}`
//         },
//       }
//     );
//     console.log("Client ajouté:", response.data);

//     // Ajouter le nouveau client en haut de la liste
//     setClients([response.data, ...clients]);

//     // Réinitialiser les champs du formulaire
//     setNameClient("");
//     setTelephone("");
//     setCnib("");
//     setSelectedGroup("");


//     setIsModalVisible(false);
//     openNotificationWithIcon('success', "Client ajouté avec succès.");
//   } catch (error) {
//     console.error("Error:", error);
//     setErrorMessage("Erreur lors de l'ajout du client. Veuillez réessayer.");
//     openNotificationWithIcon('error', error.response?.data || "Erreur lors de l'ajout du client. Veuillez réessayer.");
//   }
// };


  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!editGroup || !editGroup.nameClient || !editGroup.telephone || !editGroup.cnib || !editGroup.group) {
      setErrorMessage("Veuillez remplir tous les champs.");
      return;v
    }
    try {
      const response = await axios.put(
        `http://www.staging.messagelinkpro.com/messagelinkpro/external-api/client/modifier/${editGroup.idClient}`,
        {
          nameClient: editGroup.nameClient,
          telephone: editGroup.telephone,
          cnib: editGroup.cnib,
          group: editGroup.group,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          },
        }
      );
      console.log("Donné du client mis à jour:", response.data);
      fetchClients();
      setEditModalVisible(false);
      setEditGroup(null);
      openNotificationWithIcon('success', "Client modifié avec succès.");
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Erreur lors de la modification du client. Veuillez réessayer.");
      openNotificationWithIcon('error', "Erreur lors de la modification du client. Veuillez réessayer.");
    }
  };

  const handleDelete = async (idClient) => {
    try {
      await axios.delete(`http://www.staging.messagelinkpro.com/messagelinkpro/external-api/clientdelete/${idClient}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          },
        }
      );
      fetchClients();
      console.log("Client supprimé avec succès.");
      setDeleteModalVisible(false);
      setDeleteGroup(null);
      openNotificationWithIcon('success', "Client supprimé avec succès.");
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Erreur lors de la suppression du client. Veuillez réessayer.");
      openNotificationWithIcon('error', "Erreur lors de la suppression du client. Veuillez réessayer.");
    }
  };

  const handleView = (group) => {
    setViewGroup(group);
    setViewModalVisible(true);
  };

  const handleEdit = (group) => {
    setEditGroup(group);
    setEditModalVisible(true);
  };

  const handleDeleteConfirm = (group) => {
    setDeleteGroup(group);
    setDeleteModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNameClient("");
    setTelephone("");
    setErrorMessage("");
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert("Veuillez sélectionner un fichier.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post("http://www.staging.messagelinkpro.com/messagelinkpro/external-api/client/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`

        },

        
      });
      console.log("File uploaded successfully:", response.data);
      openNotificationWithIcon('success', "Fichier importé avec succès.");

      fetchClients();
      setIsImportModalVisible(false);
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Erreur lors de l'importation du fichier. Veuillez réessayer.");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      ) {
        console.log('Fichier Excel sélectionné :', file);
        setSelectedFile(file);
      } else {
        alert('Veuillez sélectionner un fichier Excel.');
      }
    }
  };

  const filteredClients = clients.filter((client) => {
    const nameClient = client.nameClient?.toLowerCase() || "";
    const telephone = client.telephone?.toLowerCase() || "";
    const cnib = client.cnib?.toLowerCase() || "";
    const groupId = client.group?.id || "";

    return (
      nameClient.includes(searchTerm.toLowerCase()) ||
      telephone.includes(searchTerm.toLowerCase()) ||
      cnib.includes(searchTerm.toLowerCase()) ||
      groupId === searchTerm
    );
  });
  const sortedfilteredClients= [...filteredClients].sort((a, b) => new Date(b.created) - new Date(a.created));

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7 col-auto">
                <h3 className="page-title">Clients</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active">Client</li>
                </ul>
                <div className="top-nav-search">
                  <form>
                    <input
                      className="form-control"
                      placeholder="Rechercher par nom"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className="btn" type="submit">
                      <i className="fa fa-search" />
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-sm-5  col">
  <Button
    type="primary"
    onClick={() => setIsModalVisible(true)}
    className="btn btn-primary float-end mt-2 ms-4"
  >
    Ajouter un client
  </Button>
 

  <Button
    type="primary"
    onClick={() => setIsImportModalVisible(true)}
    className="btn btn-primary float-end mt-2"
  >
     Excel
  </Button>
</div>

      <Modal
        title="Importer un fichier Excel"
        visible={isImportModalVisible}
        onCancel={() => setIsImportModalVisible(false)}
        footer={[
          <Button key="cancel" className=" btn btn-secondary ml-2" 
         onClick={() => setIsImportModalVisible(false)}>
            Annuler
          </Button>,
          <Button key="submit" type="btn btn-primary " onClick={handleFileUpload}>
            Importer
          </Button>,
        ]}
      >
        <input type="file"                        className="form-control"
             accept=".xlsx, .xls"
 onChange={handleFileChange} />
      </Modal>
              </div>
          </div>

          {/* <div className="col-sm-6 col">
            <form onSubmit={handleFileUpload}>
              <div className="form-group">
                <label>Fichier Excel</label>
                <input
                  type="file"
                  className="form-control"
                  accept=".xlsx, .xls"
                  onChange={handleFileChange}
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Importer
              </button>
            </form>
          </div> */}

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      columns={[
                        {
                          title: "Ordre",
                          render: (text, record, index) => index + 1,
                        },
                        { title: "Nom", dataIndex: "nameClient", key: "nameClient" },
                        { title: "Téléphone", dataIndex: "telephone", key: "telephone" },
                        { title: "Numéro CNIB", dataIndex: "cnib", key: "cnib" },
                        {
                          title: "Groupe",
                          dataIndex: "group",
                          key: "group",
                          render: (text, record) => (
                            <span>
                              {groups.find((group) => group.id === record.group)?.nom || ""}
                            </span>
                          ),
                        },
                        {
                          title: "Date d'ajout",
                          dataIndex: "created",
                          key: "created",
                          render: (text) => {
                            if (!text) {
                              return "";
                            }

                            const date = new Date(text);
                            const formattedDate = date.toLocaleDateString("fr-FR");
                            const formattedTime = date.toLocaleTimeString("fr-FR", {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            });

                            return `${formattedDate} ${formattedTime}`;
                          },
                        },
                        {
                          title: "Actions",
                          className:"text-center",

                          key: "actions",
                          render: (text, record) => (
                            <>
                           <div className="text-center">
                           <a
                                href="#"
                                className="me-1 btn btn-sm bg-primary-light"
                                onClick={() => handleView(record)}
                              >
                                <i className="fe fe-eye"></i> Voir
                              </a>
                              <a
                                href="#"
                                className="me-1 btn btn-sm bg-success-light"
                                onClick={() => handleEdit(record)}
                              >
                                <i className="fe fe-pencil"></i> Editer
                              </a>
                              <a
                                href="#"
                                className="me-1 btn btn-sm bg-danger-light"
                                onClick={() => handleDeleteConfirm(record)}
                              >
                                <i className="fe fe-trash"></i> Supprimer
                              </a>
                           </div>

                            </>
                          ),
                        },
                      ]}
                      dataSource={sortedfilteredClients} // Afficher les clients dans l'ordre inverse
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
{/* formulaire du clients  */}
          <Modal
            title="Ajouter un nouveau client"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
           <form onSubmit={handleSubmit}>
  <div className="form-group">
    <label>Nom</label>
    <input
      type="text"
      className="form-control"
      value={nameClient}
      onChange={(e) => setNameClient(e.target.value)}
      required // Rend le champ obligatoire
    />
      {nameClientError && (
                  <small className="text-danger">{nameClientError}</small>
       )}
  </div>
  
  <div className="form-group">
    <label>Téléphone</label>
    <PhoneNumberInput
      value={telephone}
      onChange={(value) => setTelephone(value)}
      required // Rend le champ obligatoire
    />
      {telephoneError && (
                  <small className="text-danger">{telephoneError}</small>
                )}
  </div>
  
  <div className="form-group">
    <label>Numéro CNIB</label>
    <input
      type="text"
      className="form-control"
      value={cnib}
      onChange={(e) => setCnib(e.target.value)}
      required // Rend le champ obligatoire
    />
     {cnibError && (
                  <small className="text-danger">{cnibError}</small>
                )}
  </div>
  
  <div className="form-group">
    <label>Groupe</label>
    <select
      className="form-control"
      value={selectedGroup}
      onChange={(e) => setSelectedGroup(e.target.value)}
      required // Rend le champ obligatoire
    >
      <option value="">Sélectionnez un groupe</option>
      {groups.map((group) => (
        <option key={group.id} value={group.id}>
          {group.nom}
        </option>
      ))}
    </select>
    {selectedGroupError && (
                  <small className="text-danger">{selectedGroupError}</small>
                )}
  </div>
  
  <div className="form-group">
    <button className="btn btn-primary" type="submit">
      Ajouter
    </button>
    <button
      className="btn btn-secondary ml-6"
      onClick={handleCancel}
      type="button"
    >
      Annuler
    </button>
  </div>
  
  {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
</form>
 
          </Modal>

          <Modal
            title="Modifier le client"
            visible={editModalVisible}
            onCancel={() => setEditModalVisible(false)}
            footer={null}
          >
            <form onSubmit={handleEditSubmit}>
              <div className="form-group">
                <label>Nom</label>
                <input
                  type="text"
                  className="form-control"
                  value={editGroup?.nameClient || ""}
                  onChange={(e) =>
                    setEditGroup({ ...editGroup, nameClient: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Téléphone</label>
                <PhoneNumberInput
                  value={editGroup?.telephone || ""}
                  onChange={(value) =>
                    setEditGroup({ ...editGroup, telephone: value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Numéro CNIB</label>
                <input
                  type="text"
                  className="form-control"
                  value={editGroup?.cnib || ""}
                  onChange={(e) =>
                    setEditGroup({ ...editGroup, cnib: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Groupe</label>
                <select
                  className="form-control"
                  value={editGroup?.group || ""}
                  onChange={(e) =>
                    setEditGroup({ ...editGroup, group: e.target.value })
                  }
                >
                  <option value="">Sélectionnez un groupe</option>
                  {groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.nom}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <button className="btn btn-primary" type="submit">
                  Modifier
                </button>
                <button
                  className="btn btn-secondary ml-2"
                  onClick={() => setEditModalVisible(false)}
                  type="button"
                >
                  Annuler
                </button>
              </div>
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            </form>
          </Modal>

          <Modal
            title="Détails du client"
            visible={viewModalVisible}
            onCancel={() => setViewModalVisible(false)}
            footer={null}
          >
            {viewGroup && (
              <div>
                <p>
                  <strong>Nom:</strong> {viewGroup.nameClient}
                </p>
                <p>
                  <strong>Téléphone:</strong> {viewGroup.telephone}
                </p>
                <p>
                  <strong>Numéro CNIB:</strong> {viewGroup.cnib}
                </p>
                <p>
                  <strong>Groupe:</strong> {groups.find(g => g.id === viewGroup.group)?.nom || ''}
                </p>
              </div>
            )}
          </Modal>

          <Modal
            title="Confirmation de suppression"
            visible={deleteModalVisible}
            onCancel={() => setDeleteModalVisible(false)}
            onOk={() => handleDelete(deleteGroup.id)}
          >
            <p>Êtes-vous sûr de vouloir supprimer ce client ?</p>
          </Modal>

         
        </div>
      </div>
    </>
  );
};

export default Clients;
