import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ isAllowed, redirectPath = "/", children }) => {
    
  if (!isAllowed) {
    return <Navigate to={redirectPath} />;
  }

  return children
};

export default ProtectedRoute;
